// External
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, skipWhile } from 'rxjs/operators';

// Internal
import { MessageService } from '../../core/message.service';
import { UsefulService } from '../../global/useful/useful.service';
import { ValuesService } from '../../../../common/values/values.service';
import { SubscriptionsValuesService } from '../../../../common/values/subscriptions.values.service';
import { ConnectSubscriptionTrialService } from '../../requests/connect-subscription-trial-service/connect-subscription-trial.service';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionsTrialService {

    private readonly onEligibilityForFreeTrialCheck$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private markToUpdateEligibility = true;
    private eligibilityForFreeTrialResponse = {};

    constructor(
        private readonly messageService                     : MessageService,
        private readonly valuesService                      : ValuesService,
        private readonly subscriptionsValuesService         : SubscriptionsValuesService,
        private readonly usefulService                      : UsefulService,
        private readonly connectSubscriptionTrialService    : ConnectSubscriptionTrialService
    ) {}

    /**
     * Function that returns the additional information about the reason received in response from 'activateTrialId()' function
     * @public
     * @memberof SubscriptionsTrialService
     *
     * @param {string} reason
     * @returns {object} Reason info or 'undefined'
     */
    public handleTrialResponse(reason): any {
        const redeemResponse = {
            [this.subscriptionsValuesService.trialReasons.alreadyUsed]: {
                valid: false,
                codeError: true,
                activationHint: 'subscription.trial.error.ALREADY_USED'
            },
            [this.subscriptionsValuesService.trialReasons.valid]: {
                valid: true,
                codeError: false,
                makeDryRunTrueRequest: true
            },
            default: {
                valid: false,
                codeError: false,
                activationHint: 'subscription.trial.modal.problem'
            }
        };

        const reasonInfo = redeemResponse[reason];
        if (reasonInfo) {
            return reasonInfo;
        }

        return redeemResponse.default;
    }

    /**
     * Function that calls a method used to request a trial subscription with dry_run: true
     * @public
     * @memberof SubscriptionsTrialService
     *
     * @param {string} trialId - mandatory
     * @returns {Observable} Response from server
     */
    public checkTrialId(trialId): Observable<object> {
        return this.connectSubscriptionTrialService.requestTrial(trialId, true)
        .pipe(
            map(resp => resp),
            catchError(err => {
                throw err.reason;
            })
        );
    }

    /**
     * Function that calls a method used to request a trial subscription with dry_run: false
     * @public
     * @memberof SubscriptionsTrialService
     *
     * @param {string} trialId - mandatory
     * @returns Response from server
     */
    public activateTrialId(trialId): Observable<object> {
        return this.connectSubscriptionTrialService.requestTrial(trialId, false)
        .pipe(
            map(resp => {
                const reason   = this.usefulService.getNested(resp, null, 'reason');
                if ((reason && reason === this.subscriptionsValuesService.redeemReasons.valid)) {
                    // uneori nu vine eventul de subscriptions changed
                    this.messageService.sendMessage(this.valuesService.events.subscriptionChanged, {});
                }
                return resp;
            }),
            catchError(err => {
                throw err.reason;
            })
        );
    }

    /**
     * Function that calls a method used to check the eligibility for a free trial subscription
     * @public
     * @memberof SubscriptionsTrialService
     *
     * @param {string} appId - mandatory
     * @param {string} trialId - mandatory
     * @returns Response from server or previous reponse saved, if exists
     */
    public checkEligibilityForFreeTrial(appId, trialId): Observable<any> {
        if(!this.markToUpdateEligibility) {
            return of(this.eligibilityForFreeTrialResponse);
        }

        if (this.onEligibilityForFreeTrialCheck$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onEligibilityForFreeTrialCheck$.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        } else {
            this.onEligibilityForFreeTrialCheck$.next(this.valuesService.processServiceState.INPROGRESS);
            return this.connectSubscriptionTrialService.eligibleForFreeTrial(appId, trialId)
            .pipe(
                map(resp => {
                    this.eligibilityForFreeTrialResponse = resp;
                    this.markToUpdateEligibility = false;
                    this.onEligibilityForFreeTrialCheck$.next(this.valuesService.processServiceState.DONE);
                    return resp;
                }),
                catchError(err => {
                    this.markToUpdateEligibility = true;
                    this.onEligibilityForFreeTrialCheck$.next(this.valuesService.processServiceState.DONE);
                    throw err;
                })
            );
        }
    }

    /**
     * Function that marks the eligibility request as requiring an update
     * @public
     * @memberof SubscriptionsTrialService
     *
     * @param {nothing}
     * @returns {nothing}
     */
    public updateCheckEligibilityForFreeTrial(): void {
        if (this.onEligibilityForFreeTrialCheck$.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.markToUpdateEligibility = true;
        }
    }

}
