// External
import { ActivatedRoute } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, catchError } from "rxjs/operators";
import { of, throwError, Observable } from 'rxjs';
import { tv4 } from "tv4";

// Internal
import { ConfigService } from "src/app/common/config/config.service";
import { ProfilesService } from "../../process/profiles/profiles.service";
import { RequestsService } from "../../global/request-service/requests.service";
import { ValuesService } from '../../../../common/values/values.service';
import { schemas } from "src/app/common/models/schemas";

@Injectable({
    providedIn: "root"
})
export class ParentalAdvisorAreasService {
    constructor(
        private configService: ConfigService,
        private cookieService: CookieService,
        private http: HttpClient,
        private profilesService: ProfilesService,
        private requestsService: RequestsService,
        private route: ActivatedRoute,
        private valuesService: ValuesService
    ) { }

    /**
    * This method returns a list of areas defined for the profile_id
    * @param {object} Contains mandatory `profile_id`
    * @returns Response from server
     */
    getAreasByProfile(profile_hash) {
        let profiles = this.profilesService.getParentalProfiles();
        let profile_id = profiles[profile_hash].profile_id;

        if (!tv4.validate(profile_id, schemas.schemaUuid)) {
            console.error("Invalid params", tv4.error);
            return of("Invalid params");
        }

        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "list_by_profile",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.parentalAdvisor.app_id
                },
                profile_id: profile_id,
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.parentalAdvisorAreasService,
            json,
            "POST"
        ).pipe(
            map( (resp: any) => {
                return resp.result;
            }),
            catchError((error) => {
                return throwError(error);
            })
        );
    }

    /**
    * This method is used to add new area
    * @param {object} Contains mandatory `profile_id`
    * @returns Response from server
    */
    addArea(info, profile_hash) {
        let profiles = this.profilesService.getParentalProfiles();
        let profile_id = profiles[profile_hash].profile_id;

        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "add",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                connect_destination: {
                    device_id: this.valuesService.parentalAdvisor.device_id,
                    app_id: this.valuesService.parentalAdvisor.app_id
                },
                lat         : info.lat,
                lng         : info.lng,
                name        : info.name,
                location    : info.location,
                profiles    : [profile_id],
                checkin     : info.checkin,
                radius      : parseInt(info.radius, 10),
                type        : parseInt(info.type, 10)
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.parentalAdvisorAreasService,
            json,
            "POST"
        ).pipe(
            map( (resp: any) => {
                if (resp?.result) {
                    return resp.result;
                } else {
                    throw resp;
                }
            }),
            catchError((error) => {
                throw(error);
            })
        );
    }

    /**
    * This method is used to edit area properties
    * @param {object} Contains mandatory `profile_id`
    * @returns Response from server
    */
    editArea(info, profile_hash) {
        let profiles = this.profilesService.getParentalProfiles();
        let profile_id = profiles[profile_hash].profile_id;

        var json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "set",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                connect_destination: {
                    device_id: this.valuesService.parentalAdvisor.device_id,
                    app_id: this.valuesService.parentalAdvisor.app_id
                },
                area_id: info._id,
                properties: {}
            }
        };

        if (info.hasOwnProperty("lat")) {
            json["params"]["properties"]["lat"] = info["lat"];
        }

        if (info.hasOwnProperty("lng")) {
            json["params"]["properties"]["lng"] = info["lng"];
        }

        if (info.hasOwnProperty("name")) {
            json["params"]["properties"]["name"] = info["name"];
        }

        if (info.hasOwnProperty("location")) {
            json["params"]["properties"]["location"] = info["location"];
        }

        json["params"]["properties"]["profiles"] = [profile_id];

        if (info.hasOwnProperty("radius")) {
            json["params"]["properties"]["radius"] = parseInt(info["radius"], 10);
        }

        if (info.hasOwnProperty("type")) {
            json["params"]["properties"]["type"] = parseInt(info["type"], 10);
        }

        if (info.hasOwnProperty("checkin") && typeof info["checkin"] === 'boolean') {
            json["params"]["properties"]["checkin"] = info["checkin"];
        }

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.parentalAdvisorAreasService,
            json,
            "POST"
        ).pipe(
            map( (resp: any) => {
                return resp.result;
            }),
            catchError((error) => {
                return throwError(error);
            })
        );
    }

    /**
    * This method is used to delete the area with the id given
    * @param {object} Contains mandatory `area_id`
    * @returns Response from server
    */
    removeArea(area_id) {
        var json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "remove",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                connect_destination: {
                    device_id: this.valuesService.parentalAdvisor.device_id,
                    app_id: this.valuesService.parentalAdvisor.app_id
                },
                area_id: area_id
            }
        };

        return this.requestsService.make(this.configService.config.nimbusServer, this.valuesService.parentalAdvisorAreasService, json, 'POST')
        .pipe(
            map( (resp: any) => {
                return resp.result;
            }),
            catchError((error) => {
                return throwError(error);
            })
        );
    }

    /**
   * It is a google Api (not the curent one from Central)
   * @param {object} Contains mandatory `lat`, `long`
   * @returns Response from server
   */
    getAddress(lat, long): Observable<any> {
        if (!lat || !long) {
            console.error('Invalid params', tv4.error);
            return of('Invalid params');
        }
        return this.http
        .get(
            'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
            lat +
            ',' +
            long +
            '&clientId=gme-bitdefendersrl'
        )
        .pipe(
            map( (resp: any) => {
                return resp.results;
            }),
            catchError((error) => {
                return throwError(error);
            })
        );
    }
}
