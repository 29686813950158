export enum CampaignMediaIdentifiers {
    BUTTON = 'button',
    LINK = 'link',
    DETAILS = 'details'
}

export enum CampaignNames {
    WELCOME_BUY = 'welcome-buy',
    DIP_PAGE = 'dip-page',
    DIP_DASHBOARD = 'dip-dashboard',
    ADD_DEVICES = 'add_devices',
    DASHBOARD_ADD_DEVICES = 'dashboard_add_devices',
    DIP_RENEW = 'dip-renew',
    VPN_RENEW = 'vpn-renew',
    CORE_RENEW = 'core-renew',
    PASSWORD_MANAGER_LP = 'password-manager-lp',
    PASSWORD_MANAGER_RENEW = 'password-manager-renew',
    IDTHEFT_LP = 'id-theft-tab-lp',
    VPN_LP = 'vpn-lp',
    VPN_UPSELL = 'vpn-upsell',
    IDTHEFT_RENEW = 'idtheft-renew',
    ULTIMATE_RENEW = 'ultimate-renew',
    PROTECTIONCARD2S6 = 'protectioncard2:s6',
    PROTECTIONCARD2S7 = 'protectioncard2:s7',
    PROTECTIONCARD3S8 = 'protectioncard3:s8',
    EMPTY = '',
    HOMEPAGE_PS = 'homepage_ps',
    HOMEPAGE_TS = 'homepage_ts',
    HOMEPAGE_DIP = 'homepage_dip',
    HOMEPAGE_VPN = 'homepage_vpn',
    HOMEPAGE_IDP = 'homepage_idp',
    HOMEPAGE_PM = 'homepage_pm'

}
export class CampaignParameters {
    internal: boolean;
    mediaId: CampaignMediaIdentifiers;
    campaignName?: CampaignNames;
    domain?: string
}

export enum SourceTypes {
    RENEW_CENTRAL = 'renew_central',
    UPSELL_CENTRAL = 'upsell_central',
    UPGRADE_FROM_CENTRAL_DASHBOARD = 'upgrade_from_central_dashboard',
    CENTRAL = 'central',
    EMPTY = ''
}

