// External
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

// Internal
import { ValuesService } from '../../../common/values/values.service';
import { ConnectLoginService } from '../requests/connect-login-service/connect-login.service';
import { TranslateService } from '@ngx-translate/core';
import { map, catchError } from 'rxjs/operators';
import { AuthService } from '../global/auth/auth-service.service';
import { UsefulService } from '../global/useful/useful.service';
import { RequestsService } from '../global/request-service/requests.service';
import { ConfigService } from '../../config/config.service';
import { LoginLinksService } from '../global/loginLinks/loginLinks.service';
@Injectable({ providedIn: 'root' })

export class AuthGuard implements CanActivate {
    defaultLang: string;
    isAuth = false;
    languages: any;
    path: any;
    urlParams: any;

    constructor(
        private readonly valuesService      : ValuesService,
        private readonly connectLoginService: ConnectLoginService,
        private readonly cookieService      : CookieService,
        public  readonly translateService   : TranslateService,
        private readonly usefulService      : UsefulService,
        private readonly authService        : AuthService,
        private readonly requestsService    : RequestsService,
        private readonly configService      : ConfigService,
        private readonly router             : Router,
        private readonly loginLinksService  : LoginLinksService
    ) {}

    private goToDefaultPage(url) {
        const urlParts = url.split("?");
        if (urlParts.lenght > 1) {
            return this.router.parseUrl(this.valuesService.centralPaths["dashboard"].path.concat("?", urlParts[1]));
        }
        return this.router.parseUrl(this.valuesService.centralPaths["dashboard"].path);
    }

    private navigatedToHomePage(route: ActivatedRouteSnapshot) {
        return Array.isArray(route.url) && route.url.length > 0 && route.url[0].path === this.valuesService.centralPaths.home.id;
    }

    // gets token or generates token
    private generateToken(token): Observable<any> {
        const params: any = {
            user_token: token
        };

        if (this.configService.config.loginRequestType) {
            params.type = this.configService.config.loginRequestType;
        }

        return this.connectLoginService.connect(params)
        .pipe(
            map((res) => {
                if (res.error || res.status === 'error') {
                    throw (res.error || res.internal_data);
                }

                const now = new Date();
                const exp = new Date(now.getFullYear(), now.getMonth() + 1, now.getDate());
                // 2 min for test purpose -> expire token
                this.cookieService.set(this.valuesService.cookieToken, res.result.user_token, exp, '/', "", true, "Strict");
                // this.cookieService.set(this.valuesService.cookieTokenAlt, res.result.user_token, exp, '/authorize', "", true, "Strict");

                //> Do not delete - it's used for target
                // this.cookieService.set(this.valuesService.cookieToken, res.result.user_token, exp, '/', "", true, "None");

                return of(true);
            }),
            catchError((error) => {
                throw(error || 'Timeout Exception');
            })
        );
    }

    /**
    * This method verifies if user is logged in and redirects him properly
    * @param route - ActivatedRouteSnapshot
    * @param state - RouterStateSnapshot
    * @returns {Observable} Redirects to login page or returns Observable
    */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean|UrlTree> {
        return new Observable<boolean|UrlTree>((subscriber) => {

            //* Check user_token name to use further
            const loginUserTokenName = this.configService.config.loginUserTokenName
                                        ? this.configService.config.loginUserTokenName
                                        : this.configService.config.defaultLoginUserTokenName;
            //* daca userul nu e logat si daca nu are userToken in url
            if (!this.authService.checkLogin() && !route.queryParams.hasOwnProperty(loginUserTokenName)) {
                this.requestsService.clearCookies();
                // daca nu esti logat si navighezi catre '/home' te duce pe '/home'
                if (this.navigatedToHomePage(route)) {
                    // daca nu ai '/home' pagina disponibila, te duce la '/login'
                    if (!this.configService.config.home) {
                        window.location.href = this.loginLinksService.loginPage(true, false, state.url);
                        subscriber.next(false);
                        subscriber.complete();
                    } else {
                        // aici dam raspuns pe error ca sa nu lasam requesturile din navigation guard sa se execute
                        // pentru ca nu avem inca token si o sa faileze si or sa redirecteze catre '/500'
                        subscriber.error(true);
                        subscriber.complete();
                    }
                // daca nu esti autentificat si navighezi cu parametrii de create account
                } else if (route.queryParams.hasOwnProperty('login_type') && route.queryParams.login_type === 'create_account') {
                    const redirectUrl = state.url;
                    window.location.href = this.loginLinksService.createAccountPage(false, redirectUrl);
                    subscriber.next(false);
                    subscriber.complete();
                // daca nu esti autentificat si nu navighezi catre '/home' si nici nu ai parametrii de create account
                } else {
                    window.location.href = this.loginLinksService.loginPage(true, false, state.url);
                    subscriber.next(false);
                    subscriber.complete();
                }
            // daca userul are userToken in url si navigheaza pe pagina care necesita login (diferita de '/home')
            // se face requestul pt logare
            // acest lucru impiedica buguri de genul: sunt logata cu un cont, dar daca am un link care m-ar loga cu alt cont, 
            // raman blocat cu primul cont
            } else if (!this.navigatedToHomePage(route) && route.queryParams.hasOwnProperty(loginUserTokenName)) {
                this.generateToken(route.queryParams[loginUserTokenName]).pipe(
                    map((res) => {
                        this.usefulService.setFirstLoginTimestamp();
                        subscriber.next(this.loginLinksService.fromRedirectUrlToUrl(state.url));
                        subscriber.complete();
                    }),
                    catchError((err) => {
                        window.location.href = this.loginLinksService.loginPage(true, true, state.url);
                        subscriber.next(false);
                        subscriber.complete();
                        return of(false)
                    })
                ).subscribe();
            // daca userul e logat si nu are userToken in url
            // sau e logat, are userToken in link si vrea sa navigheze pe '/home'
            } else {
                this.usefulService.checkAndSetFirstLoginTimestamp();
                // daca esti logat si ai navigat catre '/home'
                if (this.navigatedToHomePage(route)) {
                    subscriber.next(this.goToDefaultPage(state.url));
                    subscriber.complete();
                } else {
                    subscriber.next(true);
                    subscriber.complete();
                }
            }
        });
    }

}