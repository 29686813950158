import { WebmailProtectionInboxProviders } from '../../../../common/models/security/WebmailProtection.model';

export enum ConnectDestinationDeviceId {
    ALL = 'all',
    MGMT = 'mgmt'
};

/**
 * to be completed
 */
export enum PushWebmailProtectionEventFields {
    SCAN_ACTIVE = 'scan_active',
}

export enum PushEvents {
    INBOX_REGISTRATION = 'inbox_registration',
    INBOX_REMOVED = 'inbox_removed',
    INBOX_UPDATED = 'inbox_updated'
};

export interface ConnectDestinationInterface {
    device_id: ConnectDestinationDeviceId,
    app_id: string
};

export interface ConnectSourceInterface {
    device_id: string,
    app_id: string
};

export interface PushWebmailProtectionEventParamsInterface {
    err: any;
    event_name: PushEvents,
    source: ConnectSourceInterface,
    target: ConnectDestinationInterface,
    timestamp: number,
    event_params: PushWebmailProtectionEventInterface
};

export interface PushWebmailProtectionEventInterface {
    inbox_id: string,
    inbox_provider: WebmailProtectionInboxProviders,
    device_id: string,
    service_id: string,
    fields: string|PushWebmailProtectionEventFields[]
};
