// External
import { CookieService } from "ngx-cookie-service";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map, catchError } from "rxjs/operators";

// Internal
import { RequestsService } from "../../global/request-service/requests.service";
import { ValuesService } from "../../../values/values.service";
import { ConfigService } from "../../../../common/config/config.service";
import { UtilsCommonService } from '../../../../common/utils/utils-common.service';
import { LanguageService } from '../../core/language.service';

@Injectable({
    providedIn: "root"
})

export class ConnectReferralService {

    errorMessage = 'Invalid params';

    constructor(
        private readonly cookieService: CookieService,
        private readonly requestsService: RequestsService,
        private readonly valuesService: ValuesService,
        private readonly configService: ConfigService,
        private readonly utilsCommonService: UtilsCommonService,
        private readonly languageService: LanguageService
    ) { }

    /**
     * This method returns the list of campaigns the current user is eligible to generate referral codes
     * for as well as the history of rewards which were applied for the current user.
     * @returns Response from server
     */
    listCampaigns(nameSpace, includeShares = false): Observable<any> {
        if (!nameSpace) {
            return of(this.errorMessage);
        }

        const json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "list_campaigns",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    app_id: this.valuesService.connectAppId,
                    device_id: this.valuesService.connectDeviceId
                },
                "namespace": nameSpace,
                "include_shares": includeShares
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.connectReferral,
            json,
            "POST"
        ).pipe(
            map(
                (resp: any) => {
                    if (resp.error) {
                        throw resp.error        
                    }
                    
                    return resp.result;
                }
            ),
            catchError((error) => {
                throw error;
            })
        );
    }

    /**
     * This method returns a list of referrals which were generated by the current user (regardless a specific campaign).
     * @returns Response from server
     */
    listReferrals(campaignId, includeShares = false): Observable<any> {
        if (!campaignId) {
            return of(this.errorMessage);
        }

        const json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "list_referrals",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    app_id: this.valuesService.connectAppId,
                    device_id: this.valuesService.connectDeviceId
                },
                "campaign_id": campaignId,
                "include_shares": includeShares
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.connectReferral,
            json,
            "POST"
        ).pipe(
            map(
                (resp: any) => {
                    return resp.result;
                }
            ),
            catchError((error) => {
                throw error;
            })
        );
    }

    /**
     * This method allows an user to generate referral codes for ACTIVE campaigns, checking all the services from the account, and forward the generated code via email.
     * @returns Response from server
     */
    getReferralV2(params): Observable<any> {
        if (this.utilsCommonService.isEmptyObject(params)) {
            return of(this.errorMessage);
        }

        const json: any = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "get_referral_v2",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    app_id: this.valuesService.connectAppId,
                    device_id: this.valuesService.connectDeviceId
                }
            }
        };

        if (params.campaignId || params.hasOwnProperty("campaignId")) {
            json.params.campaign_id = params.campaignId;
        }

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.connectReferral,
            json,
            "POST"
        ).pipe(
            map(
                (resp: any) => {
                    return resp.result;
                }
            ),
            catchError((error) => {
                throw error;
            })
        );
    }

    /**
     * This method allows an user to apply a referral code on its account.
     * @returns Response from server
     */
    applyReferral(referralId): Observable<any> {
        if (!referralId) {
            return of(this.errorMessage);
        }

        const json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "apply_referral",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    app_id: this.valuesService.connectAppId,
                    device_id: this.valuesService.connectDeviceId
                },
                "referral_id": referralId
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.connectReferral,
            json,
            "POST"
        ).pipe(
            map(
                (resp: any) => {
                    if (resp.result) {
                        return resp.result;
                    } else {
                        throw resp;
                    }
                }
            ),
            catchError((error) => {
                throw error;
            })
        );
    }

    /**
     * This method allows an user to send a referral code, using the initial method and destinatio.
     * @returns Response from server
     */

    sendReferral(params): Observable<any> {
        if (this.utilsCommonService.isEmptyObject(params) || !params.hasOwnProperty("sendOptions")) {
            return of(this.errorMessage);
        }

        const json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "send_referral",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    app_id: this.valuesService.connectAppId,
                    device_id: this.valuesService.connectDeviceId
                },
                send_options: {
                    method        : params.sendOptions.method,
                    destination   : params.sendOptions.destination,
                    referral_type : params.sendOptions.referralType,
                    locale        : this.languageService.getLang(),
                },
                locale: this.languageService.getLang(),
                code: params.code
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.connectReferral,
            json,
            "POST"
        ).pipe(
            map(
                (resp: any) => {
                    if (resp.result) {
                        return resp.result;
                    } else {
                        throw resp;
                    }
                }
            ),
            catchError((error) => {
                throw error;
            })
        );
    }


}