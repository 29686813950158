// External
import { CookieService } from "ngx-cookie-service";
import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { Router } from "@angular/router";
import { map, catchError } from "rxjs/operators";
import { tv4 } from "tv4";

// Internal
import { ConfigService } from "src/app/common/config/config.service";
import { RequestsService } from "../../global/request-service/requests.service";
import { UtilsCommonService } from "src/app/common/utils/utils-common.service";
import { ValuesService } from "../../../values/values.service";
import { schemas } from "src/app/common/models/schemas";

@Injectable({
    providedIn: "root"
})
export class ParentalRestrictionsMgmtService {
    constructor(
        private cookieService: CookieService,
        private requestsService: RequestsService,
        private utilsCommonService: UtilsCommonService,
        private valuesService: ValuesService,
        private router: Router,
        private configService: ConfigService
    ) { }

    /**
     * This method is used to get blocked apps
     * @param {object} Contains mandatory `profile_id`
     * @returns Response from server
     */
    getBlockedApps(profile_id): Observable<any> {
        if (!tv4.validate(profile_id, schemas.schemaUuid)) {
            console.error("Invalid params", tv4.error);
            return of("Invalid params");
        }
        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "getBlockedApps",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                profile_id: profile_id
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.parentalAdvisorRestrictionsService,
            json,
            "POST"
        ).pipe(
            map( (resp) => {
                return resp.result;
            }),
            catchError((error) => {
                if (error.redirect500) {
                    return this.router.navigate(['/500'], { skipLocationChange: true });
                } else {
                    return throwError(error);
                }
            })
		);
    }

    createJsonDTL(info, index) {
        let req: any = {
            id: index,
            jsonrpc: this.valuesService.jsonrpc,
            method: "editDailyTimeLimit",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                profile_id: info.profile_id
            }
        };
        if (info.hasOwnProperty("restriction_id")) {
            req["params"]["restriction_id"] = info["restriction_id"]; 
        }

        if (info.hasOwnProperty("active")) {
            req["params"]["active"] = info["active"]; 
        }

        if (info.hasOwnProperty("days")) {
            req["params"]["days"] = info["days"]; 
        }

        if (!info.hasOwnProperty("restriction_id")) {
            req["params"]["active"] = true;
        }

        return req;
    }

    /**
     * This method is used to edit daily time limit
     * @param {object} Contains mandatory `info.profile_id`, `info.days`
     * @returns Response from server
     */
    editTimeLimitData(info): Observable<any> {
        let batch: any = [];
        if (!tv4.validate(info.profile_id, schemas.schemaUuid)) {
            console.error("Invalid params", tv4.error);
            return of("Invalid params");
        }
        for (let i = 0; i < info.params.length; i++) {
            let ret = this.createJsonDTL(info.params[i], i);
            batch.push(ret);
        }
        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.parentalAdvisorRestrictionsService,
            batch,
            "POST"
        ).pipe(
            map(
                (resp) => {
                    if (Array.isArray(resp) && resp.length !== 0){
                        var temp_arr = [];
                        for(var i in resp){
                            temp_arr.push( resp[i].result );
                        }
                        return temp_arr;
                    } else {
                        return resp.result;
                    }
                }
            ),
            catchError((error) => {
                throw error;
            })
        );
    }

    /**
     * This method setDTL and set new one according to the time specified in the days array
     * @param {object} Contains mandatory `info.profile_id`, `info.days`
     * @returns Response from server
     */
    updateDtl(info): Observable<any> {
        if (!tv4.validate(info.profile_id, schemas.schemaUuid) || this.utilsCommonService.isEmptyObject(info)) {
            console.error("Invalid params", tv4.error);
            return of("Invalid params");
        }
        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "updateDTL",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                profile_id: info.profile_id,
                days: info.days
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.parentalAdvisorRestrictionsService,
            json,
            "POST"
        ).pipe(
            map( (resp) => { return resp.result; }),
            catchError((error) => { throw error;})
        );
    }

    /**
     * This method is used to list daily time limit
     * @param {object} Contains mandatory `profile_id`
     * @returns Response from server
     */
    getDTL(profile_id): Observable<any> {
        if (!tv4.validate(profile_id, schemas.schemaUuid)) {
            console.error("Invalid params", tv4.error);
            return of("Invalid params");
        }
        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "listDailyTimeLimits",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                profile_id: profile_id
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.parentalAdvisorRestrictionsService,
            json,
            "POST"
        ).pipe(
            map(
                (resp) => {
                    return resp.result;
                }
            ),
            catchError((error) => {
                return throwError(error);
            })
        );
    }

    /**
     * This method is used to edit blocked apps
     * @param {object} Contains mandatory `info.profile_id`, `info.restrictions` and `info.device_id`
     * @returns Response from server
     */
    // editBlockedApps(info): Observable<any> {
    //     if (
    //         !tv4.validate(info.profile_id, schemas.schemaUuid) ||
    //         !tv4.validate(info.device_id, schemas.schemaUuid) ||
    //         !this.utilsCommonService.checkArray(info.restrictions) ||
    //         info.restrictions.length === 0 ||
    //         this.utilsCommonService.isEmptyObject(info)
    //     ) {
    //         console.error("Invalid params", tv4.error);
    //         return of("Invalid params");
    //     }
    //     let json = {
    //         id: parseInt((Math.random() * 100).toString(), 10),
    //         jsonrpc: this.valuesService.jsonrpc,
    //         method: "editAppRestriction",
    //         params: {
    //             connect_source: {
    //                 user_token: this.cookieService.get(this.valuesService.cookieToken),
    //                 device_id: this.valuesService.connectDeviceId,
    //                 app_id: this.valuesService.connectAppId
    //             },
    //             connect_destination: {
    //                 device_id: info.device_id,
    //                 app_id: this.valuesService.bundlePA
    //             },
    //             profile_id: info.profile_id,
    //             restrictions: info.restrictions
    //         }
    //     };

    //     return this.requestsService.make(
    //         this.configService.config.nimbusServer,
    //         this.valuesService.parentalAdvisorRestrictionsService,
    //         json,
    //         "POST"
    //     ).pipe(
    //         map( (resp) => {
    //             return resp.result;
    //         }),
    //         catchError((error) => {
    //             if (error.redirect500) {
    //                 return this.router.navigate(['/500'], { skipLocationChange: true });
    //             } else {
    //                 return throwError(error);
    //             }
    //         })
	// 	);
    // }

    /**
     * This method is used to remove daily time limit
     * @param {object} Contains mandatory `info.profile_id`, `info.restriction_ids`
     * @returns Response from server
     */
    // removeLimitData(info): Observable<any> {
    //     if (
    //         !tv4.validate(info.profile_id, schemas.schemaUuid) ||
    //         !this.utilsCommonService.checkArray(info.restriction_ids) ||
    //         info.restriction_ids.length === 0
    //     ) {
    //         console.error("Invalid params", tv4.error);
    //         return of("Invalid params");
    //     }
    //     let json = {
    //         id: parseInt((Math.random() * 100).toString(), 10),
    //         jsonrpc: this.valuesService.jsonrpc,
    //         method: "removeDailyTimeLimit",
    //         params: {
    //             connect_source: {
    //                 user_token: this.cookieService.get(this.valuesService.cookieToken),
    //                 device_id: this.valuesService.connectDeviceId,
    //                 app_id: this.valuesService.connectAppId
    //             },
    //             profile_id: info.profile_id,
    //             restriction_ids: info.restriction_ids
    //         }
    //     };

    //     return this.requestsService.make(
    //         this.configService.config.nimbusServer,
    //         this.valuesService.parentalAdvisorRestrictionsService,
    //         json,
    //         "POST"
    //     ).pipe(
    //         map(
    //             (resp) => {
    //                 return resp.result;
    //             }
    //         ),
    //         catchError((error) => {
    //             throw error;
    //         })
    //     );
    // }

    /**
     * This method is block third party apps
     * @param {object} Contains mandatory `info.device_id`, `info.profile_id`, `info.block`
     * @returns Response from server
     */
    // blockThirdPartyApps(info): Observable<any> {
    //     if (
    //         !tv4.validate(info.profile_id, schemas.schemaUuid) ||
    //         !tv4.validate(info.device_id, schemas.schemaUuid) ||
    //         !this.utilsCommonService.checkBoolean(info.block) ||
    //         this.utilsCommonService.isEmptyObject(info)
    //     ) {
    //         console.error("Invalid params", tv4.error);
    //         return of("Invalid params");
    //     }
    //     let json = {
    //         id: parseInt((Math.random() * 100).toString(), 10),
    //         jsonrpc: this.valuesService.jsonrpc,
    //         method: "blockThirdPartyApps",
    //         params: {
    //             connect_source: {
    //                 user_token: this.cookieService.get(this.valuesService.cookieToken),
    //                 device_id: this.valuesService.connectDeviceId,
    //                 app_id: this.valuesService.connectAppId
    //             },
    //             connect_destination: {
    //                 device_id: info.device_id,
    //                 app_id: this.valuesService.bundlePA
    //             },
    //             profile_id: info.profile_id,
    //             block: info.block
    //         }
    //     };

    //     return this.requestsService.make(
    //         this.configService.config.nimbusServer,
    //         this.valuesService.parentalAdvisorRestrictionsService,
    //         json,
    //         "POST"
    //     );
    // }
}
