// External
import { Injectable } from '@angular/core';
import { of, BehaviorSubject } from 'rxjs';
import { catchError, skipWhile, map } from 'rxjs/operators';

// Internal
import { CommercialIdsMgmtService } from '../../requests/connect-commercial-ids-service/connect-commercial-ids.service';
import { SubscriptionsService } from '../subscriptions/subscriptions.service';
import { UtilsCommonService } from 'src/app/common/utils/utils-common.service';
import { ValuesService } from 'src/app/common/values/values.service';
import { UsefulService } from '../../global/useful/useful.service';

@Injectable({
    providedIn: 'root'
})

export class CommercialidsService {

    private commercialIds: any = {};

    private onlistCommercialsIds$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private markToUpdate_commercialsIds: boolean = true;

    constructor(
        private commercialIdsMgmtService: CommercialIdsMgmtService,
        private subscriptionsService: SubscriptionsService,
        private utilsCommonService: UtilsCommonService,
        private valuesService: ValuesService,
        private usefulService: UsefulService,
    ) {}
    //*
    //*  Aux-functions
    //*
    //#region
    /**
     * Computes the final commercialIds Object based on the array of commercialIds and the array
     * of coresponding commercialIds info.
     * @param {Array} comercialIdsArray 
     * @param {Array} commercialIdsInfo 
     * @returns {Object} commercialIds Object
     */
    _computeCommercialIds(comercialIdsArray, commercialIdsInfo) {
        let commercialIds = {};
        for (let i = 0; i < comercialIdsArray.length; i++) {
            if (this.utilsCommonService.isObject(commercialIdsInfo[i]) && !this.utilsCommonService.isEmptyObject(commercialIdsInfo[i])) {
                commercialIds[comercialIdsArray[i]] = commercialIdsInfo[i];
            } else {
                commercialIds[comercialIdsArray[i]] = JSON.parse(JSON.stringify(this.valuesService.defaultCommercialId));
            }
        }
        return commercialIds;
    }
    //#endregion

    //*
    //*  Request-related
    //*
    //#region

    /**
     * Sends batch request with all commercialIds for all subscription apps. 
     * Then populates commercialIds Object with info about the commercialIds.
     */
    list() {
        // !! modificat
        const  comercialIdsArray = this.subscriptionsService.getCommercialIds();
        if (!comercialIdsArray || comercialIdsArray.length == 0) {
            return of(this.commercialIds);
        }

        if(!this.markToUpdate_commercialsIds) {
            return of(this.commercialIds);
        }

        if (this.onlistCommercialsIds$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onlistCommercialsIds$.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE),
                );
        } else {
            this.onlistCommercialsIds$.next(this.valuesService.processServiceState.INPROGRESS);
            return this.commercialIdsMgmtService.commercialDetails(comercialIdsArray)
                .pipe(
                    map((resp: any) => {
                        if (resp) {
                            this.commercialIds = this._computeCommercialIds(comercialIdsArray, resp);
                            this.markToUpdate_commercialsIds = false;
                            this.onlistCommercialsIds$.next(this.valuesService.processServiceState.DONE);
                        } else {
                            this.markToUpdate_commercialsIds = true;
                            this.onlistCommercialsIds$.next(this.valuesService.processServiceState.DONE);
                            return of('malformed_request');
                        }
                    }),
                    catchError( (err) => {
                        this.markToUpdate_commercialsIds = true;
                        this.onlistCommercialsIds$.next(this.valuesService.processServiceState.DONE);
                        //! Trebuie sa returneze succes pe eroare sa nu blocheze celelalte req in data resolver.
                        return of(err);
                    })
                )
        }
    }

    /**
     * Marks commercialIds Object as suitable for updating only if there is not another update in progress.
     * Update = redo list request.
     */
    updateCommercialIds() {
        if (this.onlistCommercialsIds$.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.markToUpdate_commercialsIds = true;
        }
    }
    //#endregion

    //*
    //*  Getters
    //*
    //#region

    /**
     * Getter for commercialIds Object.
     * @returns {Object} commercialIds
     */
    getAll() {
        return this.commercialIds;
    }

    getCommercialBuyLink(commercialId, commercialUrl) {
        if (!commercialId || !commercialUrl) {
            return null;
        }
        return this.commercialIds?.[commercialId]?.[commercialUrl] ? this.commercialIds?.[commercialId]?.[commercialUrl] : null;
    }

    /**
     * Gets home url from commercialIds.
     * @returns {String} home url if there are any commercialIds, empty string otherwise
     */
    getHomePageUrl() {
        let commercialIdsKeys = Object.keys(this.commercialIds);
        if (commercialIdsKeys.length > 0 && this.commercialIds[commercialIdsKeys[0]].webpage) {
            return this.commercialIds[commercialIdsKeys[0]].webpage;
        }
        return '';
    }

    resetInProgressFlags() {
        this.onlistCommercialsIds$.next(this.valuesService.processServiceState.DONE);
    }
    //#endregion
}
