// External
import { CookieService } from 'ngx-cookie-service';
import { Injectable} from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, skipWhile, takeUntil } from 'rxjs/operators';

// Internal
import { ConfigService } from '../../config/config.service';
import { DevicesService } from '../process/devices/devices.service';
import { IssuesService } from '../process/issues/issues.service';
import { MessageService } from './message.service';
import { PrivacyService } from '../process/privacy/privacy.service';
import { ProfilesService } from '../process/profiles/profiles.service';
import { SettingsService } from '../process/settings/settings.service';
import { SubscriptionsService } from '../process/subscriptions/subscriptions.service';
import { UtilsCommonService } from '../../utils/utils-common.service';
import { ValuesService } from '../../values/values.service';
import { ParentalService } from '../process/parental/parental.service';
import { NotificationsService } from '../process/notifications/notifications.service';
import { CommercialidsService } from '../process/commercialids/commercialIds.service';
import { PrivacyEvents, PrivacyValuesService } from '../../values/privacy.values.service';
import { TechassistService } from '../process/techassist/techassist.service';
import { DevicesDetailsService } from '../process/devices/devices-details/devices-details.service';
import { DevicesValuesService } from '../../values/devices.values';
import { RedeemService } from '../process/subscriptions/redeem.service';
import { ReferralService } from '../process/referral/referral.service';
import { ReferralValuesService } from '../../values/referral.values.service';
import { DashboardService } from '../process/dashboard/dashboard.service';
import { RequestsService } from '../global/request-service/requests.service';
import { NavigationService } from '../../navigation/navigation.service';
import { InvitesService } from '../process/subscriptions/invites.service';
import { SubscriptionsTrialService } from '../process/subscriptions/subscriptionTrial.service';
import { WebmailProtectionService } from '../process/security/webmailprotection.service';
import { ConnectDestinationInterface, ConnectDestinationDeviceId, PushEvents, PushWebmailProtectionEventParamsInterface } from './models/Push.model';


@Injectable({
    providedIn: 'root'
})

export class PushService {

    loadedServices: Observable<number>;
    connectFactory: any;
    _events: any = [];
    _data: any = [];
    _notifications_ids = [];
    _push_registred: boolean = false;

    markToUpdateUnregisterPush = true;
    private readonly onlistUnregisterPush$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private readonly onDestroy$: Subject<void> = new Subject<void>();

    constructor(
        private readonly configService: ConfigService,
        private readonly cookieService: CookieService,
        private readonly devicesService: DevicesService,
        private readonly issuesService: IssuesService,
        private readonly messageService: MessageService,
        private readonly parentalService: ParentalService,
        private readonly privacyService: PrivacyService,
        private readonly profilesService: ProfilesService,
        private readonly settingsService: SettingsService,
        private readonly subscriptionsService: SubscriptionsService,
        private readonly utilsCommonService: UtilsCommonService,
        private readonly valuesService: ValuesService,
        private readonly devicesValuesService: DevicesValuesService,
        private readonly notificationsService: NotificationsService,
        private readonly commercialidsService: CommercialidsService,
        private readonly invitesService: InvitesService,
        private readonly techAssistService: TechassistService,
        private readonly devicesDetailsService: DevicesDetailsService,
        private readonly redeemService: RedeemService,
        private readonly requestsService: RequestsService,
        private readonly referralService: ReferralService,
        private readonly referralValuesService: ReferralValuesService,
        private readonly dashboardService: DashboardService,
        private readonly privacyValuesService: PrivacyValuesService,
        private readonly navigationService: NavigationService,
        private readonly subscriptionsTrialService: SubscriptionsTrialService,
        private readonly webmailProtectionService: WebmailProtectionService
    ) {
        this.messageService.getMessage()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
            (res) => {
                if (res.text === this.valuesService.events.subscriptionChanged) {
                    this.subscriptionsChangedActions();
                    this.messageService.sendMessage(this.valuesService.events.triggerStart, { });
                    this.navigationService.forceRouteReload();
                }
            }
        );
    }

    registerSettings(device_id, app_id) {

        let connect_source = {
            "user_token": this.cookieService.get(this.valuesService.cookieToken),
            "app_id": this.valuesService.connectAppId,
            "device_id": this.utilsCommonService.getWebDeviceId()
        };

        let cloud_host = this.configService.config.nimbusServer;
        let connect = window['connect_factory'](connect_source, cloud_host, null);
        // TO CHECK if $rootScope.connectFactory is needed

        //*** Register connect to events ***//
        let connect_destination = {
            "device_id": device_id,
            "app_id": app_id
        };
        let event_name = ["settings_changed"];
        this.connectFactory.register_events(connect_destination, event_name, function (_err, _event_name, _source, _target, _timestamp, _event_params) {
            var params = {
                err: _err,
                event_name: _event_name,
                source: _source,
                target: _target,
                timestamp: _timestamp,
                event_params: _event_params
            };

            switch (_event_name) {
                case 'settings_changed':
                    this.eventName.emit({
                        id: 'push:settings_changed',
                        params: {
                            device_id: _event_params.destination.device_id,
                            app_id: _event_params.destination.app_id,
                            event_name: _event_name
                        }
                    });
                    break;
                default:
                    break;
            }
        });
    };

    //*** Register connect to events ***//
    registerPush(): Observable<any> {
        if (this._push_registred) {
            return of(true);
        }

        const pushObservable = new Observable(observer => {
            if (this._push_registred) {
                observer.next();
                observer.complete();
                return pushObservable;
                // return;
            }

            var connect_source = {
                "user_token": this.cookieService.get(this.valuesService.cookieToken),
                "app_id": this.valuesService.connectAppId,
                "device_id": this.utilsCommonService.getWebDeviceId()
            };

            var cloud_host = this.configService.config.nimbusServer;
            var connect = window['connect_factory'](connect_source, cloud_host, null);
            this.connectFactory = connect;

            //*** Register connect to events ***//
            var connect_destination = {
                "device_id": ConnectDestinationDeviceId.ALL,
                "app_id": this.valuesService.appDvi
            };
            var event_name = ["device_info_changed", "device_removed", "new_device", "applications_list_changed", "padv_schedules_changed", "padv_daily_limit_changed"];
            connect.register_events(connect_destination, event_name, function (_err, _event_name, _source, _target, _timestamp, _event_params) {
                var params = {
                    err: _err,
                    event_name: _event_name,
                    source: _source,
                    target: _target,
                    timestamp: _timestamp,
                    event_params: _event_params
                };

                switch (_event_name) {
                    case 'settings_changed':
                        this.gather('push:settings_changed', {
                            device_id: _event_params.destination.device_id,
                            app_id: _event_params.destination.app_id
                        });
                        break;
                    case 'device_info_changed':
                        this.gather('push:device_info_changed', {
                            device_id: _event_params.device_id,
                            fields: _event_params.fields
                        });
                        break;
                    case 'device_removed':
                        this.gather('push:device_removed', {
                            device_id: _event_params.device_id
                        });
                        break;
                    case 'new_device':
                        this.gather('push:new_device', {
                            device_id: _event_params.device_id
                        });
                        break;
                    default:
                        break;
                }
            }.bind(this));

            //*** Register parental to events ***//
            connect_destination = {
                "device_id": ConnectDestinationDeviceId.ALL,
                "app_id": this.valuesService.bundlePA
            };
            event_name = ["padv_schedules_changed", "padv_schedule_state_changed", "padv_daily_limit_changed", "parental_settings_changed"];
            connect.register_events(connect_destination, event_name, function (_err, _event_name, _source, _target, _timestamp, _event_params) {
                var params = {
                    err: _err,
                    event_name: _event_name,
                    source: _source,
                    target: _target,
                    timestamp: _timestamp,
                    event_params: _event_params
                };


                switch (_event_name) {
                    case 'padv_schedules_changed':
                        this.gather('push:padv_schedules_changed', {
                            profile_id: _event_params.profile_id,
                            event_name: _event_name
                        });
                        break;
                    case 'padv_schedule_state_changed':
                        this.gather('push:padv_schedule_state_changed', {
                            profile_id: _event_params.profile_id,
                            event_name: _event_name
                        });
                        break;
                    case 'padv_daily_limit_changed':
                        this.gather('push:padv_daily_limit_changed', {
                            profile_id: _event_params.profile_id,
                            event_name: _event_name
                        });
                        break;
                    case 'parental_settings_changed':
                        this.gather('push:parental_settings_changed', {
                            profile_id: _event_params.profile_id,
                            event_name: _event_name,
                            path: _event_params.path
                        });
                        break;
                    default:
                        break;
                }

            }.bind(this));

            //*** Register connect to events ***//

            connect_destination = {
                "device_id": ConnectDestinationDeviceId.MGMT,
                "app_id": this.valuesService.connectAppId
            };
            event_name = ["task_updated", "scan_changed", "issues_changed", "subscription_changed", "location_changed", "account_changed", "settings_changed",
                "send_pic_info", "applications_list_changed", "padv_schedules_changed", "ghostr_exceptions_changed", "connect_referral__reward_applied",
                //privacy events
                "data_privacy_init_done", "data_privacy_timeline_event", "data_privacy_new_issue", "data_privacy_summary_updated", "data_privacy_manual_scan_done",
                "data_privacy_identity_deleted", "data_privacy_identity_parked", "data_privacy_identity_unparked", "lid_scan"
            ];
            connect.register_events(connect_destination, event_name, function (_err, _event_name, _source, _target, _timestamp, _event_params) {
                var params = {
                    err: _err,
                    event_name: _event_name,
                    source: _source,
                    target: _target,
                    timestamp: _timestamp,
                    event_params: _event_params
                };

                switch (_event_name) {
                    case 'task_updated':
                        this.gather('push:task_updated', params);
                        break;
                    case 'ghostr_exceptions_changed':
                        this.gather('push:ghostr_exceptions_changed', params);
                        break;
                    case 'scan_changed':
                        this.gather('push:scan_changed');
                        break;
                    case 'issues_changed':
                        this.gather('push:issues_changed', params);
                        break;
                    case 'applications_list_changed':
                        this.gather('push:applications_list_changed', {
                            device_id: _source.device_id
                        });
                        break;
                    case 'subscription_changed':
                        this.gather('push:subscription_changed', params);
                        break;
                    case 'location_changed':
                        this.gather('push:location_changed', params);
                        break;
                    case 'account_changed':
                        this.gather('push:account_changed', params);
                        break;
                    case 'send_pic_info':
                        this.gather('push:send_pic_info', params);
                        break;
                    case 'settings_changed':
                        this.gather('push:settings_changed', params);
                        break;
                    case 'connect_referral__reward_applied':
                        this.gather('push:connect_referral__reward_applied', params);
                        break;
                    //privacy events
                    case 'data_privacy_init_done':
                        this.gather('push:data_privacy_init_done', params);
                        break;
                    case  'lid_scan':
                        this.gather('push:lid_scan', params);
                        break;
                    case 'data_privacy_timeline_event':
                        this.gather('push:data_privacy_timeline_event', params);
                        break;
                    case 'data_privacy_new_issue':
                        this.gather('push:data_privacy_new_issue', params);
                        break;
                    case 'data_privacy_summary_updated':
                        this.gather('push:data_privacy_summary_updated', params);
                        break;
                    case 'data_privacy_manual_scan_done':
                        this.gather('push:data_privacy_manual_scan_done', params);
                        break;
                    case 'data_privacy_identity_deleted':
                        this.gather('push:data_privacy_identity_deleted', params);
                        break;
                    case 'data_privacy_identity_parked':
                        this.gather('push:data_privacy_identity_parked', params);
                        break;
                    case 'data_privacy_identity_unparked':
                        this.gather('push:data_privacy_identity_unparked', params)
                        break;
                    default:
                        break;
                }
            }.bind(this));

            //*** Register connect to notifications ***//
            connect.register_notifications(
                function (_err, _user_fields, _notification_id, _source, _timestamp, _notification_params) {
                    if (typeof(_notification_id) !== undefined) {
                        this.gather('push:notification_received', _notification_id);
                    }
                }.bind(this),
                function (_err, _notification_id) {
                    if (typeof(_notification_id) !== undefined) {
                        this.gather('push:notification_recalled', _notification_id);
                    }
                }.bind(this)
            );
            //*** Register connect to notifications ***//

            this.registerWebmailProtectionEvents(connect);

            this._push_registred = true;
            observer.next();
            observer.complete();
        });

        return pushObservable;
    }

    /**
     * Registrats the webmail protection events.
     * @param connect The connect script that does the registration.
     */
    private registerWebmailProtectionEvents(connect: any): void {
        const connectDestination: ConnectDestinationInterface = {
            device_id: ConnectDestinationDeviceId.ALL,
            app_id: this.valuesService.appWebmailProtection
        };
        const eventNames: PushEvents[] = [PushEvents.INBOX_REGISTRATION, PushEvents.INBOX_REMOVED, PushEvents.INBOX_UPDATED];
        const  gatherWebmaiProtectionEventsBindedFunction = function (err, event_name, source, target, timestamp, event_params) {
            const params: PushWebmailProtectionEventParamsInterface = {
                err,
                event_name,
                source,
                target,
                timestamp,
                event_params
            };
            this.gather(event_name, params);
        }
        connect.register_events(connectDestination, eventNames, gatherWebmaiProtectionEventsBindedFunction.bind(this));
    }

    //* Function that treats all events and afterwards triggers a message through the application to reprocess components
    treat_them_all() {
        let i, antitheft, optimizer, parental, protection, va_scan, activeDevice, settings;
        let optional: any = {};

        if (this._events.indexOf('push:padv_schedules_changed') !== -1) {
            if (this._data.length !== 0) {
                for (i in this._data) {
                    switch (this._data[i].task_name) {
                        case 'padv_schedules_changed':
                            // parental = this.parentalService.get();
                            // if(parental && parental[this._data[i].profile_id]){
                            //   delete parental[this._data[i].profile_id].schedulesRaw;
                            // }
                            break;
                        default:
                            break;
                    }
                }
            }
        }
        if (this._events.indexOf('push:padv_schedules_state_changed') !== -1) {
            if (this._data.length !== 0) {
                for (i in this._data) {
                    switch (this._data[i].task_name) {
                        case 'padv_schedules_state_changed':
                        // parental = parentalService.get();
                        // if(parental && parental[this._data[i].profile_id]){
                        //   delete parental[this._data[i].profile_id].schedulesRaw;
                        // }
                        // break;
                        default:
                            break;
                    }
                }
            }
        }
        if (this._events.indexOf('push:padv_daily_limit_changed') !== -1) {
            if (this._data.length !== 0) {
                for (i in this._data) {
                    switch (this._data[i].task_name) {
                        case 'padv_daily_limit_changed':
                            // parental = parentalService.get();
                            // if(parental && parental[this._data[i].profile_id]){
                            //   delete parental[this._data[i].profile_id].daily_limiter;
                            // }
                            break;
                        default:
                            break;
                    }
                }
            }
        }

        if (this._events.indexOf('push:parental_settings_changed') !== -1) {
            if (this._data.length !== 0) {
                for (i in this._data) {
                    switch (this._data[i].task_name) {
                        case 'parental_settings_changed':
                            switch (this._data[i].path[0]) {
                                case 'limits':
                                case 'schedules':
                                case 'status':
                                case 'screentime':
                                    this.parentalService.updateScreenTime(this._data[i].profile_id, this._data[i].path[0]);
                                    break;
                                case 'apps':
                                    this.parentalService.updateApps(this._data[i].profile_id);
                                    break;
                                case 'web':
                                    this.parentalService.updateWeb(this._data[i].profile_id);
                                    break;
                                case 'contacts':
                                    this.parentalService.updateSetingsContacts(this._data[i].profile_id);
                                    break;
                                case 'location':
                                    this.parentalService.updateSetingsLocation(this._data[i].profile_id);
                                    break;
                                default:
                                    break;
                            }
                            break;
                        default:
                            break;
                    }
                }
            }
        }
        // if(_events.indexOf('push:padv_schedules_changed') !== -1){
        //     $parental.get();
        // }
        if (this._events.indexOf('push:padv_schedules_state_changed') !== -1) {
            // accountService.set({});
        }
        if (this._events.indexOf('push:account_changed') !== -1) {
            this.profilesService.updateOwner();
        }
        if (this._events.indexOf('push:issues_changed') !== -1) {
            if (this._data.length !== 0) {
                for (const i in this._data) {
                    switch (this._data[i].task_name) {
                        case 'issues_changed':
                            // un device poate trece din at risk in protected sau invers si trebuiesc luate din nou info despre device
                            this.devicesService.updateDevices();
                            this.devicesDetailsService.updateIssues(this._data[i].device_id);
                            this.messageService.sendMessage(this.valuesService.events.devices.issuesChanged, {});
                            break;
                        default:
                            break;
                    }
                }
            }
        }

        if (this._events.indexOf('push:subscription_changed') !== -1) {
            this.subscriptionsChangedActions();
        }

        if (this._events.indexOf('push:connect_referral__reward_applied') !== -1) {
            if (this._data.length !== 0) {
                for (i in this._data) {
                    if (this._data[i].task_name === 'connect_referral__reward_applied') {
                        this.referralService.updateListCampaigns(this.referralValuesService.nameSpaces.central);
                        this.referralService.openCongratsReferralModal(this._data[i].event_params);
                    }
                }
            }
        }

        if (this._events.indexOf('push:ghostr_exceptions_changed') !== -1) {
            this.devicesService.updateExceptions();
        }
        if (this._events.indexOf('push:device_info_changed') !== -1) {
            for (const data of this._data) {
                if (data.task_name ==='push:device_info_changed' && data.fields && data.device_id) {
                    for (const setting of data.fields) {
                        if (this.valuesService.deviceSettings[setting]) {
                            this.settingsService.updateOneDeviceSetting(setting, data.device_id);
                        } else {
                            switch (setting) {
                                case 'device_icon':
                                case 'friendly_name':
                                case 'display_name':
                                    this.devicesService.updateDevices();
                                    break;
                                case 'traffic':
                                    if (data.device_id) {
                                        this.devicesService.updateDeviceTraffic(data.device_id);
                                    } else {
                                        this.devicesService.updateAllTraffic();
                                    }
                                    break;
                                case 'apps':
                                case 'agent_status':
                                case 'agent_app_id':
                                case 'agent_detected_name':
                                    this.appsChangedGeneralActions();
                                    this.appsChangedActions(data.device_id);
                                    break;
                                default:
                                    this.devicesService.updateDevices();
                                    this.devicesService.updateAllTraffic();
                                    this.settingsService.updateAllDeviceSettings();
                            }
                        }
                    }
                }
            }
            optional.type = 'device_info_changed';
        }

        if (this._events.indexOf('push:new_device') !== -1) {
            if (this._data.length) {
                localStorage.setItem('installPage', '0');
                this.devicesService.updateDevices();
                this.devicesService.updateExceptions();
                this.subscriptionsService.updateSubscriptions();
                this.issuesService.remove();
                this.dashboardService.updateActiveDeviceIds();
                this.dashboardService.updateAllThreats();
            }

            for (const i in this._data) {
                if (this._data[i].task_name === 'push:new_device') {
                    this.redeemService.setNewDeviceId(this._data[i].device_id);
                }
            }
        }

        if (this._events.indexOf('push:applications_list_changed') !== -1 && this._data.length !== 0) {
            for (const i in this._data) {
                if (this._data[i].task_name === 'push:applications_list_changed') {
                    this.appsChangedActions(this._data[i].device_id);
                }
            }
            this.appsChangedGeneralActions();
        }
        if (this._events.indexOf('push:device_removed') !== -1) {
            this.removeDeviceActions();
        }

        if (this._events.indexOf('push:location_changed') !== -1) {
            if (this._data.length !== 0) {
                for (i in this._data) {
                    switch (this._data[i].task_name) {
                        case 'location_changed':
                            this.devicesDetailsService.updateLocationOnDevice(this._data[i].device_id);
                            // daca nu trimit mesaj specific, se face dataResolver la orice event
                            this.messageService.sendMessage(this.valuesService.events.devices.locationChanged, {});
                            break;
                        default:
                            break;
                    }
                }
            }
        }
        if (this._events.indexOf('push:task_updated') !== -1) {
            if (this._data.length !== 0) {
                for (i in this._data) {
                    switch (this._data[i].task_name) {
                        case this.devicesValuesService.tasksName.optimizer.optimize:
                            this.devicesDetailsService.updateOptimizeOnDevice(this._data[i].device_id);
                            break;
                        case this.devicesValuesService.tasksName.optimizer.startup:
                            this.devicesDetailsService.updateStartupOnDevice(this._data[i].device_id);
                            break;
                        case this.devicesValuesService.tasksName.malware.system:
                            this.devicesDetailsService.updateMalwareScan(this._data[i].device_id);
                            break;
                        case this.devicesValuesService.tasksName.malware.quick:
                            this.devicesDetailsService.updateMalwareQuickScan(this._data[i].device_id);
                            break;
                        case this.devicesValuesService.tasksName.vulnerability.assessment:
                            this.devicesDetailsService.updateVulnerability(this._data[i].device_id);
                            break;
                        case this.devicesValuesService.tasksName.vulnerability.scan:
                            this.devicesDetailsService.updateVulnerability(this._data[i].device_id);
                            break;
                        default:
                            break;
                    }
                }
            }
        }
        if (this._events.indexOf('push:send_pic_info') !== -1) {
            if (this._data.length !== 0) {
                for (i in this._data) {
                    switch (this._data[i].task_name) {
                        case 'send_pic_info':
                            this.devicesDetailsService.updateSnapPhotonOnDevice(this._data[i].device_id);
                            this.messageService.sendMessage(this.valuesService.events.devices.snapPhotoChanged, {});
                            break;
                        default:
                            break;
                    }
                }
            }
        }
        if (this._events.indexOf('push:settings_changed') !== -1) {
            this.settingsService.updateAccountSettings();
            if (this._data.length !== 0) {
                for (i in this._data) {
                    if (this._data[i].task_name === 'settings_changed') {
                        const deviceId = this._data[i].device_id;
                        this.settingsService.updateOneDeviceSetting(this.valuesService.deviceSettings.pin, deviceId);
                        this.devicesDetailsService.updateAntitheftOnDevice(deviceId);
                        this.messageService.sendMessage(this.valuesService.events.devices.settingsChanged, {});
                    }
                }
            }
        }
        //privacy events
        if (this._events.indexOf('push:data_privacy_init_done') !== -1) {
            //console.log('s-a primit eventul data_privacy_init_done');
            //trimit evenimentul de "new data found" pt privacy
            this.messageService.sendMessage(PrivacyEvents.IDENTITY_WAS_FOUND, { found: true });
            optional.type = 'privacy';
        }

        if (this._events.indexOf('push:lid_scan') !== -1) {
            for (const event of this._data) {
                if (event.task_name === 'lid_scan' && event.scan_status === this.privacyValuesService.breachesStatusScan.done) {
                    this.dashboardService.updateBreaches();
                    break;
                }
            }
        }

        if (this._events.indexOf('push:data_privacy_timeline_event') !== -1) {
            //console.log('s-a primit eventul data_privacy_timeline_event');
            this.privacyService.updateEventsList();
            this.privacyService.updateExposureData();
            optional.type = 'events';
        }
        if (this._events.indexOf('push:data_privacy_new_issue') !== -1) {
            //console.log('s-a primit eventul data_privacy_new_issue', this._data);
            this.privacyService.updateBreachesList();
            this.privacyService.updateImpersonationsList();
            optional.type = 'issues';
        }
        if (this._events.indexOf('push:data_privacy_summary_updated') !== -1) {
            //console.log('s-a primit eventul data_privacy_summary_updated');
            this.privacyService.updateActivityExposureSummary();
            this.privacyService.updateActivityBreachesSummary();
            this.privacyService.updateActivityImpersonationsSummary();
            this.messageService.sendMessage(PrivacyEvents.SUMMARY_RELOADED, {});
            optional.type = 'activityExposureSummary';
        }
        if (this._events.indexOf('push:data_privacy_manual_scan_done') !== -1) {
            this.privacyService.updateProvidedIdentity();
            this.messageService.sendMessage(PrivacyEvents.MANUAL_SCAN_DONE, { manual_scan_done: true });
            optional.type = 'manual_scan';
        }

        if (this._events.indexOf('push:data_privacy_identity_deleted') !== -1) {
            this.privacyService.updateProvidedIdentity();
            optional.type = 'deleted_identity';
        }

        if (this._events.indexOf('push:data_privacy_identity_parked') !== -1) {
            this.privacyService.updateProvidedIdentity();
            optional.type = 'parked_identity';
        }

        if (this._events.indexOf('push:data_privacy_identity_unparked') !== -1) {
            this.privacyService.updateProvidedIdentity();
            optional.type = 'unparked_identity';
        }

        if (this._events.indexOf('push:notification_received') !== -1) {
            this.notificationsService.updateNotifications(JSON.parse(JSON.stringify(this._notifications_ids)));
            this.notificationsService.updateUnread();
        }

        if (this._events.indexOf('push:notification_recalled') !== -1) {
            this.notificationsService.updateAllNotifications();
            this.notificationsService.updateUnread();
        }

        this.handleWebmailProtectionEvents();

        this.messageService.sendMessage('triggerStart', { id: 'val', data: this._data });

        this.navigationService.forceRouteReload();

        //! $rootScope.$broadcast('triggerStart', optional);
        // Test this
        this._data = [];
        this._events = [];
        this._notifications_ids = [];

    };

    //* Function that gathers all events until they are 2 seconds apart. In this case they are all treated.
    //* Specific events come with certain data. This data is also stored in an array
    gather(event, data) {
        let timer;

        if (event === 'push:account_changed') {
            if (data && data.hasOwnProperty('event_params') && data.event_params.hasOwnProperty('device_id') && data.event_params.hasOwnProperty('task_name')) {
                this._data.push({ device_id: data.event_params.device_id, task_name: data.event_params.task_name });
            }
        }

        if (event === 'push:new_device') {
            if (data && data.hasOwnProperty('device_id')) {
                this._data.push({ device_id: data.device_id, task_name: event });
            }
        }

        if (event === 'push:device_info_changed') {
            if (data && data.hasOwnProperty('device_id') && data.hasOwnProperty('fields')) {
                this._data.push({ device_id: data.device_id, task_name: event, fields: data.fields });
            }
        }

        if (event === 'push:applications_list_changed') {
            if (data && data.hasOwnProperty('device_id')) {
                this._data.push({ device_id: data.device_id, task_name: event });
            }
        }

        if (event === 'push:task_updated') {
            if (data && data.hasOwnProperty('event_params') && data.event_params.hasOwnProperty('device_id') && data.event_params.hasOwnProperty('task_name')) {
                this._data.push({ device_id: data.event_params.device_id, task_name: data.event_params.task_name });
            }
        }

        if (event === 'push:send_pic_info') {
            if (data && data.hasOwnProperty('source') && data.source.hasOwnProperty('device_id') && data.hasOwnProperty('event_name')) {
                this._data.push({ device_id: data.source.device_id, task_name: data.event_name });
            }
        }

        if (event === 'push:location_changed') {
            if (data && data.hasOwnProperty('source') && data.source.hasOwnProperty('device_id') && data.hasOwnProperty('event_name')) {
                this._data.push({ device_id: data.source.device_id, task_name: data.event_name });
            }
        }

        if (event === 'push:settings_changed') {
            if (data && data.hasOwnProperty('event_params') && data.event_params.hasOwnProperty('destination') && data.event_params.destination.hasOwnProperty('device_id')) {
                this._data.push({ device_id: data.event_params.destination.device_id, task_name: data.event_name });
            }
        }

        if (event === 'push:padv_schedules_changed') {
            if (data && data.hasOwnProperty('profile_id')) {
                this._data.push({ profile_id: data.profile_id, task_name: data.event_name });
            }
        }

        if (event === 'push:padv_daily_limit_changed') {
            if (data && data.hasOwnProperty('profile_id')) {
                this._data.push({ profile_id: data.profile_id, task_name: data.event_name });
            }
        }

        if (event === 'push:padv_schedules_state_changed') {
            if (data && data.hasOwnProperty('profile_id')) {
                this._data.push({ profile_id: data.profile_id, task_name: data.event_name });
            }
        }

        if (event === 'push:parental_settings_changed') {
            if (data && data.hasOwnProperty('profile_id')) {
                this._data.push({ profile_id: data.profile_id, task_name: data.event_name, path: data.path });
            }
        }

        if (event === 'push:issues_changed') {
            if (data && data.hasOwnProperty('source') && data.source.hasOwnProperty('device_id') && data.hasOwnProperty('event_name')) {
                this._data.push({ device_id: data.source.device_id, task_name: data.event_name });
            }
        }

        if (event == 'push:connect_referral__reward_applied') {
            if (data && data.hasOwnProperty('event_name') && data.hasOwnProperty('event_params')) {
                this._data.push({task_name: data.event_name, event_params: data.event_params});
            }
        }

        // privacy
        if (event === 'push:data_privacy_new_issue') {
            if (data && data.hasOwnProperty('event_params') && data.event_params.hasOwnProperty('issues_count') && data.event_params.hasOwnProperty('issue_types')) {
                this._data.push({ issues_count: data.event_params.issues_count, issue_types: data.event_params.issue_types });
            }
        }

        if (event == 'push:lid_scan') {
            if (data && data.hasOwnProperty('event_params') && data.event_params.hasOwnProperty('scan_status')) {
                this._data.push({ task_name: data.event_name, scan_status: data.event_params.scan_status });
            }
        }

        // notifications
        if (event === 'push:notification_received') {
            this._notifications_ids.push(data);
        }

        if (this._events.length === 0) {
            this._events.push(event);
            timer = setTimeout(() => { this.treat_them_all() }, 2000);
        }
        else {
            this._events.push(event);
            clearTimeout(timer);
            timer = setTimeout(() => { this.treat_them_all() }, 2000);
        }

    };


    //*** Unregister connect to events ***//
    unregisterPush() {
        if (!this.markToUpdateUnregisterPush) {
            return;
        }

        if (this.onlistUnregisterPush$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onlistUnregisterPush$.asObservable()
                .subscribe().add(
                    () => {
                        skipWhile(res => res !== this.valuesService.processServiceState.DONE);
                    }
                );
        } else {
            this.onlistUnregisterPush$.next(this.valuesService.processServiceState.INPROGRESS);
            const unregisterPushApps = [
                {
                    app: this.valuesService.appDvi,
                    topic: 'event'
                },
                {
                    app: this.valuesService.bundlePA,
                    topic: 'event'
                },
                {
                    app: this.valuesService.connectAppId,
                    topic: 'event'
                },
                {
                    app: 'all',
                    topic: 'notification'
                }
            ];
            const jsons = [];
            let id = parseInt((Math.random() * 100).toString(), 10);

            for (const item of unregisterPushApps) {
                const json = {
                    id: id++,
                    jsonrpc: this.valuesService.jsonrpc,
                    method: 'unregister',
                    params: {
                        connect_source: {
                            user_token: this.cookieService.get(this.valuesService.cookieToken),
                            app_id: this.valuesService.connectAppId,
                            device_id: this.valuesService.connectDeviceId
                        },
                        connect_destination: {
                            device_id: ConnectDestinationDeviceId.ALL,
                            app_id: item.app
                        },

                        platform: 'secure_web',
                        topic: item.topic
                    }
                };

                jsons.push(json);
            }

            this.requestsService.make(
                this.configService.config.nimbusServer,
                this.valuesService.connectPushService,
                jsons,
                'POST'
            ).pipe(
                catchError(err => {
                    return of(true);
                })
            ).subscribe().add(
                () => {
                    this.onlistUnregisterPush$.next(this.valuesService.processServiceState.DONE);
                    this.markToUpdateUnregisterPush = false;
                }
            );
        }
    }

    /**
     * Handles the webmail protection events after the timeout is up.
     */
    private handleWebmailProtectionEvents(): void {
        if (this._events.indexOf(PushEvents.INBOX_REGISTRATION) !== -1
            || this._events.indexOf(PushEvents.INBOX_REMOVED) !== -1
            || this._events.indexOf(PushEvents.INBOX_UPDATED) !== -1) {
            this.subscriptionsService.updateSubscriptions();
            this.webmailProtectionService.updateWebmailProtectionListInboxes();
            this.webmailProtectionService.updateWebmailProtectionInboxesActivity();
        }
    }

    subscriptionsChangedActions() {
        this.subscriptionsService.updateSubscriptions();
        this.invitesService.updateInvites();
        this.commercialidsService.updateCommercialIds();
        this.subscriptionsTrialService.updateCheckEligibilityForFreeTrial();
        this.profilesService.updateOwner(); // in case of msp / xsp subscription
        this.privacyService.updateProvidedIdentity();
        this.devicesService.updateDevices();
        this.devicesService.updateExceptions();
        this.techAssistService.updateSchedules();
        this.referralService.updateListCampaigns(this.referralValuesService.nameSpaces.central);
    }

    removeDeviceActions() {
        this.devicesService.updateDevices();
        this.devicesService.updateExceptions();
        this.subscriptionsService.updateSubscriptions();
        this.commercialidsService.updateCommercialIds();
        this.dashboardService.updateActiveDeviceIds();
        this.dashboardService.updateAllThreats();
        // force route reload on device removal
        this.messageService.sendMessage(this.valuesService.events.triggerStart, { });
        this.navigationService.forceRouteReload();
    }

    appsChangedGeneralActions() {
        this.commercialidsService.updateCommercialIds();
        this.devicesService.updateDevices();
        this.subscriptionsService.updateSubscriptions();
        this.profilesService.updateProfies(); // pt parental
        this.devicesService.updateExceptions();
        this.dashboardService.updateActiveDeviceIds();
        this.dashboardService.updateAllThreats();
    }

    appsChangedActions(deviceId) {
        this.devicesDetailsService.updateIssues(deviceId);
        this.devicesDetailsService.updateThreats(deviceId);
        this.settingsService.updateDeviceSettings(deviceId);
    }

}
