import { trigger, animate, transition, style, query, group } from '@angular/animations';

export const fadeAnimation =

    trigger('fadeAnimation', [

        transition( '* <=> *', [

            query(':enter, :leave',
                [
                    style({ opacity: 0, position: 'absolute', width: '100%' })
                ], 
                { optional: true }
            ),

            group([
                query(':leave', 
                    [
                        style({ opacity: 1 }),
                        animate('0.2s', style({ opacity: 0 }))
                    ], 
                    { optional: true }
                ),

                query(':enter', 
                    [
                        style({ opacity: 0 }),
                        animate('0.2s', style({ opacity: 1 }))
                    ], 
                    { optional: true }
                )
            ])

        ])

    ]);