// External
import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

// Internal
import { ValuesService } from 'src/app/common/values/values.service';


@Injectable({
    providedIn: 'root'
})

export class AuthService {

    defaultLang: string;
    isAuth: boolean = false;
    languages: any;
    path: any;
    urlParams: any;

    constructor(
        private cookieService: CookieService,
        private valuesService: ValuesService,
        public translateService: TranslateService,
    ) { }

    // checks token
    checkLogin() {
        const rsp = this.cookieService.get(this.valuesService.cookieToken);
        if (rsp || rsp !== '') {
            return true;
        } else {
            return false;
        }
    }
    
}
