// External
import { CookieService } from "ngx-cookie-service";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { tv4 } from "tv4";

// Internal
import { RequestsService } from "../../global/request-service/requests.service";
import { ValuesService } from "../../../../common/values/values.service";
import { schemas } from "src/app/common/models/schemas";
import { ConfigService } from "src/app/common/config/config.service";
import { map, catchError } from "rxjs/operators";
import { SubscriptionsValuesService } from "src/app/common/values/subscriptions.values.service";
import { UtilsCommonService } from "src/app/common/utils/utils-common.service";

@Injectable({
providedIn: "root"
})
export class ConnectSubscriptionTrialService {
constructor(
    private cookieService: CookieService,
    private requestService: RequestsService,
    private valuesService: ValuesService,
    private configService: ConfigService,
    private readonly utilsCommonService: UtilsCommonService,
    private readonly subscriptionsValuesService: SubscriptionsValuesService
) { }

codeIsEmpty(trialId) {
    return !trialId;
}

codeIsInvalid(trialId) {
    return !tv4.validate(trialId, schemas.schemaUuid);
}

    /**
     * This method is used to to request a trial subscription
     * @param {object} Contains mandatory `trial_id`
     * @returns Response from server
     */
    requestTrial(trialId, dryRun): Observable<any> {
        if (this.codeIsEmpty(trialId)) {
            console.error("Empty code");
            return of({
                reason: this.subscriptionsValuesService.trialReasons.empty
            });
        }

        if (this.codeIsInvalid(trialId)) {
            console.error("Invalid params", tv4.error);
            return of({
                reason: this.subscriptionsValuesService.trialReasons.invalid
            });
        }

        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: "2.0",
            method: "request",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    app_id: this.valuesService.connectAppId,
                    device_id: "web"
                },
                trial_id: trialId,
                dry_run: dryRun
            }
        };

        return this.requestService.make(
            this.configService.config.nimbusServer,
            this.valuesService.subscriptionTrial,
            json,
            "POST"
        ).pipe(
            map( (resp: any) => {
                // daca fac ceck si nu am raspuns valid
                if (dryRun && !resp?.result?.subscriptions && !resp?.result?.reason) {
                    resp = {
                        result: {
                            reason: this.subscriptionsValuesService.redeemReasons.unexpectedError
                        }
                    };
                    throw resp;
                // daca fac check si am raspuns valid
                } else if (dryRun && resp?.result?.subscriptions) {
                    // adaug server time pt ca lipseste
                    if (!resp.result.subscriptions.server_time) {
                        resp.result.subscriptions.server_time = Math.floor(new Date().getTime()/1000);
                    }
                    return resp.result.subscriptions;
                }

                // daca fac activate si am raspuns valid
                if (!dryRun && resp?.result?.status === 0) {
                    resp = {
                        result: {
                            reason: this.subscriptionsValuesService.redeemReasons.valid
                        }
                    };
                }

                return resp.result;
            }),
            catchError((error) => {
                if (!error?.reason) {
                    error = {
                        reason: this.subscriptionsValuesService.redeemReasons.unexpectedError
                    }
                }
                throw error;
            })
        );
    }

    /**
     * This method is used to request a trial subscription with dry_run true
     * @param {object} Contains mandatory `trial_id`
     * @returns Response from server
     */
    trialDryRun(trialId): Observable<any> {
        if (!tv4.validate(trialId, schemas.schemaUuid)) {
            console.error("Invalid params", tv4.error);
            return of("Invalid params");
        }

        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: "2.0",
            method: "request",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    app_id: this.valuesService.connectAppId,
                    device_id: "web"
                },
                dry_run: true,
                trial_id: trialId
            }
        };

        return this.requestService.make(
            this.configService.config.nimbusServer,
            this.valuesService.subscriptionTrial,
            json,
            "POST"
        ).pipe(
            map( (resp: any) => {
                return resp.result;
            }),
            catchError((error) => {
                throw error;
            })
        );
    }

    eligibleForTrial(deviceId, appId) {
        if (!tv4.validate(deviceId, schemas.schemaUuid) ||
            !this.utilsCommonService.checkString(appId)) {
            console.error("Invalid params", tv4.error);
            return of("Invalid params");
        }

        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: "2.0",
            method: "request",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    app_id: this.valuesService.upgradeApps[appId],
                    device_id: deviceId
                },
                dry_run: true,
                trial_id: this.valuesService.trialIds[this.valuesService.upgradeApps[appId]]
            }
        };

        return this.requestService.make(
            this.configService.config.nimbusServer,
            this.valuesService.subscriptionTrial,
            json,
            "POST"
        ).pipe(
            map( (resp: any) => {
                if (resp?.result?.status === 0 
                    && resp?.result?.subscriptions 
                    && !this.utilsCommonService.isEmptyObject(resp.result.subscriptions)) {
                    return resp.result;
                } else {
                    if (resp?.result) {
                        throw resp.result;
                    } else {
                        throw resp;
                    }
                }
            }),
            catchError((error) => {
                throw error;
            })
        );
    }

    /**
     * This method is used to to check the eligibility for a free trial subscription
     * @param {string} appId - mandatory
     * @param {string} trialId - mandatory
     * @returns Response from server
     */
    eligibleForFreeTrial(appId, trialId) {
        if (!this.utilsCommonService.checkString(appId)) {
            console.error("Invalid params", tv4.error);
            return of("Invalid params");
        }

        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: "2.0",
            method: "request",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    app_id: appId,
                    device_id: "web"
                },
                dry_run: true,
                trial_id: trialId
            }
        };

        return this.requestService.make(
            this.configService.config.nimbusServer,
            this.valuesService.subscriptionTrial,
            json,
            "POST"
        ).pipe(
            map( (resp: any) => {
                if (resp?.result?.status === 0 && resp?.result?.subscriptions && !this.utilsCommonService.isEmptyObject(resp?.result?.subscriptions)) {
                    return resp.result.subscriptions;
                } else {
                    if (resp?.result) {
                        throw resp.result;
                    } else {
                        throw resp;
                    }
                }
            }),
            catchError((error) => {
                throw error;
            })
        );
    }

     /**
     * This method is used to request the start of a free trial subscription
     * @param {string} appId - mandatory
     * @param {string} trialId - mandatory
     * @returns Response from server
     */
    startFreeTrial(appId, trialId) {
        if (!this.utilsCommonService.checkString(appId)) {
            console.error("Invalid params", tv4.error);
            return of("Invalid params");
        }

        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: "2.0",
            method: "request",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    app_id: appId,
                    device_id: "web"
                },
                trial_id: trialId
            }
        };

        return this.requestService.make(
            this.configService.config.nimbusServer,
            this.valuesService.subscriptionTrial,
            json,
            "POST"
        ).pipe(
            map( (resp: any) => {
                if (resp?.result?.status === 0) {
                    return resp.result;
                } else {
                    throw resp;
                }
            }),
            catchError((error) => {
                throw error;
            })
        );
    }

}
