// External
import { ActivatedRouteSnapshot, CanActivate, UrlTree, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

// Internal
import { NavigationService } from '../../navigation/navigation.service';
import { QueryParamsService } from '../core/query.params.service';


@Injectable({ providedIn: 'root' })

export class ServicesWebmailProtectionGuard implements CanActivate {

    constructor(
        private readonly navigationService: NavigationService,
        private readonly queryParamsService: QueryParamsService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
        const urlProperties = this.navigationService.processUrl(route, state);
        this.queryParamsService.saveQueryParamsNoPersistance(urlProperties.queryObject);
        return of(this.queryParamsService.getCleanedRouteOfCleanQueryParams(urlProperties));
    }
}