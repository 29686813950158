// External
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tv4 } from 'tv4';

// Internal
import { RequestsService } from '../../global/request-service/requests.service';
import { UtilsCommonService } from '../../../utils/utils-common.service';
import { ValuesService } from '../../../values/values.service';
import { ConfigService } from 'src/app/common/config/config.service';
import { map, catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})

export class CommercialIdsMgmtService {

    constructor(
        private requestService: RequestsService,
        private utilsCommonService: UtilsCommonService,
        private valuesService: ValuesService,
        private configService: ConfigService
    ) { }

    /**
     * Makes call to retreive commercialIds info.
     * @param {object} commercial one commercialId or an array of commercialIds
     * @returns Response from server(a.k.a. response.result). If param is an array, 
     * the response is an array of info containing the '.result' for every commercialId in the param array. 
     * If commercialId does not have '.result', an empty Object will be added to the returned array.
     */
    commercialDetails(commercial): Observable<any> {
        let json: any;

        if (!this.utilsCommonService.checkArray(commercial)) {
            if (!this.utilsCommonService.checkString(commercial) || !commercial) {
                console.error('Invalid params', tv4.error);
                return of('Invalid params');
            }
            json = {
                id: parseInt((Math.random() * 100).toString(), 10),
                jsonrpc: this.valuesService.jsonrpc,
                method: 'get_commercial_id_details',
                params: {
                    commercial_details_id: commercial
                }
            };

            return this.requestService.make(
                this.configService.config.nimbusServer, 
                this.valuesService.commercial_ids, json, 
                'POST').pipe(
                    map(
                        (resp) => {
                            return resp.result;
                        }
                    ),
                    catchError((error) => {
                        throw error;
                    })
                );
        } else {
            if (commercial.length === 0) {
                console.error('Invalid params', tv4.error);
                return of('Invalid params');
            }
            json = [];
            for (let i in commercial) {
                if (commercial.hasOwnProperty(i) && commercial[i] !== '') {
                    const info = {
                        id: i,
                        jsonrpc: this.valuesService.jsonrpc,
                        method: 'get_commercial_id_details',
                        params: {
                            commercial_details_id: commercial[i]
                        }
                    };
                    json.push(info);
                }
            }

            return this.requestService.make(
                this.configService.config.nimbusServer, 
                this.valuesService.commercial_ids, 
                json, 
                'POST'
            ).pipe(
                map(
                    (resp) => {
                        let results = [];
                        for (let i = 0; i < resp.length; i++) {
                            if (resp[i].result) {
                                results.push(resp[i].result);
                            } else {
                                results.push({});
                            }
                        }
                        return results;
                    }
                ),
                catchError((error) => {
                    throw error;
                })
            );
        }
    }
}
