// External
import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { tv4 } from 'tv4';

// Internal
import { ConfigService } from 'src/app/common/config/config.service';
import { RequestsService } from '../../global/request-service/requests.service';
import { UsefulService } from '../../global/useful/useful.service';
import { UtilsCommonService } from 'src/app/common/utils/utils-common.service';
import { ValuesService } from '../../../values/values.service';
@Injectable({
    providedIn: 'root'
})
export class SeccenterService {
    constructor(
        private configService: ConfigService,
        private cookieService: CookieService,
        private requestService: RequestsService,
        private usefulService: UsefulService,
        private utilsCommonService: UtilsCommonService,
        private valuesService: ValuesService,
    ) { }

    /**
     * This method is used to get all reported threats by threat_group_id and to get reported threats batch by threat_group_id
     * @param {object} Contains mandatory `notifications`
     * @returns Response from server
    */
    threatsInfo(info): Observable<any> {

        let inf: any = info;
        let jsons = [];
        let json = {};
        let id = parseInt((Math.random() * 10000).toString(), 10)

        if (Array.isArray(inf) && inf.length !== 0) {
            jsons = [];
            for (let i in inf) {
                inf[i].id = id;
                jsons.push({
                    id: id++,
                    jsonrpc: this.valuesService.jsonrpc,
                    method: 'get_group_threat_info',
                    params: {
                        connect_source: {
                            user_token: this.cookieService.get(
                                this.valuesService.cookieToken
                            ),
                            device_id: this.valuesService.connectDeviceId,
                            app_id: this.valuesService.connectAppId
                        },
                        threats_group_id: inf[i].threat_group_id,
                        //threats_count: inf[i].threats_count
                    }
                });
            }
        } else if (this.utilsCommonService.isObject(inf) && !this.utilsCommonService.isEmptyObject(inf)) {
            json = {
                id: parseInt((Math.random() * 100).toString(), 10),
                jsonrpc: this.valuesService.jsonrpc,
                method: 'get_group_threat_info',
                params: {
                    connect_source: {
                        user_token: this.cookieService.get(this.valuesService.cookieToken),
                        device_id: this.valuesService.connectDeviceId,
                        app_id: this.valuesService.connectAppId
                    },
                    threats_group_id: inf.threat_group_id,
                    //threats_count: inf.threats_count
                }
            };
            inf.id = id;
        } else {
            return of('Invalid params');
        }

        return this.requestService.make(
            this.configService.config.nimbusServer,
            this.valuesService.secCenter,
            !this.utilsCommonService.isEmptyObject(json) ? json : jsons,
            'POST'
        )
        .pipe(
            map(
                (res: any) => {
                    if (res.status && res.status === 'error' && res.code) {
                        const data = [{
                            data : {
                                ...res
                            },
                            ...inf
                        }];
                        return data;
                    }
                    
                    if (Array.isArray(res) && res.length !== 0){
                        const temp_arr = [];
                        for(const i in res){
                            const data = res[i].result ? res[i].result : res[i].error.data;

                            for(const j in inf) {
                                if(inf[j].id === res[i].id) {
                                    temp_arr.push({
                                        ...inf[j],
                                        data
                                    });
                                    break;
                                }
                            }
                        }
                        return temp_arr;
                    }
                    return res.result;
                }
            ),
            catchError((error) => {
                throw error;
            }),
        );
    }

    /**
     * This method is used to count how many treats had ocurred in a given interval grouped by day,
     * @param {object} Contains mandatory `date_offset`, `days`
     * @returns Response from server
    */
    countThreats(days, deviceId?) {
        const dateOffset = this.usefulService.computeOffset();

        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: 'count_threats',
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                'date_offset': dateOffset,
                'days': days
            }
        };

        if (deviceId) {
            json.params["device_id"] = deviceId;
        }

        return this.requestService.make(
            this.configService.config.nimbusServer,
            this.valuesService.secCenter,
            json,
            'POST'
        ).pipe(
            map( (resp: any) => {
                return resp.result;
            }),
            catchError((error) => {
                throw error;
            })
        );
    }

    /**
     * This method is used to get the top X devices that reported threats
     * @param {object} Contains mandatory `date_offset`, `top`
     * @returns Response from server
    */
    topActiveDevices() {
        let dateOffset  = this.usefulService.computeOffset();
        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "get_active_devices",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                date_offset : dateOffset,
                top: 100
            }
        };

        return this.requestService.make(
            this.configService.config.nimbusServer,
            this.valuesService.secCenter,
            json,
            'POST'
        )
        .pipe(
            map( (resp: any) => {
                return resp.result;
            }),
            catchError((error) => {
                throw error;
            })
        );;
    };

    /**
     * This method is used to retrieve threats reported for the current user
     * @param {object} Contains mandatory `info` object with details
     * @returns Response from server
     */
    getThreatsDetails(info): Observable<any> {
        if (!this.utilsCommonService.isObject(info) && this.utilsCommonService.isEmptyObject(info)) {
            return of('Invalid params');
        }

        let json: any = {
            id: 1,
            jsonrpc: this.valuesService.jsonrpc,
            method: 'get_threats_v2',
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                }
            }
        };

        if (info.device_id) {
            json.params.device_id = info.device_id;
        }

        if (info.count) {
            if (info.count < 100) {
                json.params.count = info.count;
            } else {
                json.params.count = 100;
            }
        }

        if (info.offset) {
            json.params.offset = info.offset;
        }

        if (info.from_date) {
            json.params.from_date = info.from_date;
        }

        if (info.to_date) {
            json.params.to_date = info.to_date;
        }

        return this.requestService.make(
            this.configService.config.nimbusServer,
            this.valuesService.secCenter,
            json,
            'POST'
        ).pipe(
            map( (resp: any) => {
                return resp.result;
            }),
            catchError((error) => {
                throw error;
            })
        );
    }


    /**
     * This method is used to count how many treats had ocurred in a given interval grouped by day, object_type and threat_type
     * @param {object} Contains mandatory `device_ids`
     * @returns Response from server
     */
    // getProtectionData2(device_ids): Observable<any> {
    //     const dateOffset = this.usefulService.computeOffset();
    //     if (!this.utilsCommonService.checkArray(device_ids) || !dateOffset) {
    //         console.error('Invalid params', tv4.error);
    //         return of('Invalid params');
    //     }
    //     if (device_ids.length === 0) {
    //         device_ids = [];
    //         device_ids[0] = '';
    //     }
    //     let arr = [];

    //     for (let i in device_ids) {
    //         if (device_ids.hasOwnProperty(i)) {
    //             let json = {
    //                 id: i,
    //                 jsonrpc: this.valuesService.jsonrpc,
    //                 method: 'count_threats',
    //                 params: {
    //                     connect_source: {
    //                         user_token: this.cookieService.get(
    //                             this.valuesService.cookieToken
    //                         ),
    //                         device_id: this.valuesService.connectDeviceId,
    //                         app_id: this.valuesService.connectAppId
    //                     },
    //                     date_offset: dateOffset,
    //                     device_id: device_ids[i]
    //                 }
    //             };
    //             arr.push(json);
    //         }
    //     }

    //     if (arr.length !== 0) {
    //         return this.requestService.make(
    //             this.configService.config.nimbusServer,
    //             this.valuesService.secCenter,
    //             arr,
    //             'POST'
    //         );
    //     }
    // }


    // get_threat_info(ids): Observable<any> {
    //     let json = {
    //         id: parseInt((Math.random() * 100).toString(), 10),
    //         jsonrpc: this.valuesService.jsonrpc,
    //         method: 'get_threat_info',
    //         params: {
    //             connect_source: {
    //                 user_token: this.cookieService.get(this.valuesService.cookieToken),
    //                 device_id: this.valuesService.connectDeviceId,
    //                 app_id: this.valuesService.connectAppId
    //             },
    //             threat_ids: ids
    //         }
    //     };

    //     return this.requestService.make(
    //         this.configService.config.nimbusServer,
    //         this.valuesService.secCenter,
    //         json,
    //         'POST'
    //     );
    // }

    /**
     * This method is used to retrieve threats reported for the current user
     * @returns Response from server
     */
    // getProtectionDataDay(): Observable<any> {
    //     const dateOffset = this.usefulService.computeOffset();
    //     if (!dateOffset) {
    //         console.error('Invalid params', tv4.error);
    //         return of('Invalid params');
    //     }

    //     let json = {
    //         id: 1,
    //         jsonrpc: this.valuesService.jsonrpc,
    //         method: 'get_last_threats_report',
    //         params: {
    //             connect_source: {
    //                 user_token: this.cookieService.get(this.valuesService.cookieToken),
    //                 device_id: this.valuesService.connectDeviceId,
    //                 app_id: this.valuesService.connectAppId
    //             },
    //             offset: dateOffset
    //         }
    //     };

    //     return this.requestService.make(
    //         this.configService.config.nimbusServer,
    //         this.valuesService.secCenter,
    //         json,
    //         'POST'
    //     );
    // }

}
