// External
import { ActivatedRoute } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { Injectable } from "@angular/core";
import { map, catchError } from "rxjs/operators";
import { of, throwError } from 'rxjs';
import { tv4 } from "tv4";
// Internal
import { ConfigService } from "src/app/common/config/config.service";
import { ProfilesService } from "../../process/profiles/profiles.service";
import { RequestsService } from "../../global/request-service/requests.service";
import { ValuesService } from '../../../../common/values/values.service';
import { schemas } from "src/app/common/models/schemas";
@Injectable({
    providedIn: "root"
})
export class ParentalcontrolLocationService {
    constructor(
        private configService: ConfigService,
        private cookieService: CookieService,
        private profilesService: ProfilesService,
        private requestsService: RequestsService,
        private route: ActivatedRoute,
        private valuesService: ValuesService
    ) { }
    /**
    * This method returns state_feature for location page
    * @param {object} Contains mandatory `profile_id` and `state_feature` on fields
    * @returns Response from server
     */
    getState(profile_hash) {
        let profiles = this.profilesService.getParentalProfiles();
        let profile_id = profiles[profile_hash].profile_id;
        if (!tv4.validate(profile_id, schemas.schemaUuid)) {
            console.error("Invalid params", tv4.error);
            return of("Invalid params");
        }
        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "get",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                connect_destination: {
                    device_id: this.valuesService.parentalControl.device_id,
                    app_id: this.valuesService.parentalControl.app_id
                },
                "profile_id": profile_id,
                "fields": ["state_feature"]
            }
        };
        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.parentalControlLocationService,
            json,
            "POST"
        ).pipe(
            map( (resp: any) => {
                return resp.result;
            }),
            catchError((error) => {
                return throwError(error);
            })
        );
    }
    /**
    * This method edits location settings
    * @param {object} Contains mandatory `profile_id` and `state_feature`
    * @returns Response from server
     */
    editState(state, profile_hash) {
        let profiles = this.profilesService.getParentalProfiles();
        let profile_id = profiles[profile_hash].profile_id;
        if (!tv4.validate(profile_id, schemas.schemaUuid)) {
            console.error("Invalid params", tv4.error);
            return of("Invalid params");
        }
        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "edit",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                connect_destination: {
                    device_id: this.valuesService.parentalControl.device_id,
                    app_id: this.valuesService.parentalControl.app_id
                },
                "profile_id": profile_id,
                "state_feature": state
            }
        };
        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.parentalControlLocationService,
            json,
            "POST"
        ).pipe(
            map( (resp: any) => {
                return resp.result;
            }),
            catchError((error) => {
                return throwError(error);
            })
        );
    }
}