// External
import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tv4 } from 'tv4';

// Internal
import { RequestsService } from '../../global/request-service/requests.service';
import { ValuesService } from '../../../values/values.service';
import { schemas } from '../../../../common/models/schemas';
import { ConfigService } from '../../../../common/config/config.service';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AntiTheftMgmtService {

  constructor(
    readonly cookieService: CookieService,
    readonly requestsService: RequestsService,
    readonly valuesService: ValuesService,
    readonly configService: ConfigService
  ) { }


    /**
     * This method returns the last known location of the device and the timestamp when that location was received
     * @param {object} Contains mandatory `device_id` and optional `limit`, `skip`, `sort`
     * @returns Response from server
     */
    getSnap(deviceId): Observable<any> {
        if (!tv4.validate(deviceId, schemas.schemaUuid)) {
            return of('Invalid params');
        }

        const json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: 'get_pic_info',
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    app_id: this.valuesService.connectAppId,
                    device_id: this.valuesService.connectDeviceId
                },
                connect_destination: {
                    device_id: deviceId
                },
                limit: 4,
                skip: 0
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.antitheftMgmtService,
            json,
            'POST'
        ).pipe(
            map(
                (resp: any) => {
                    return resp.result;
                }
            ),
            catchError((error) => {
                throw error;
            })
        );
    }

    /**
     * This method allows the user to remove one or multiple picture records
     * @param {object} Contains mandatory `photo_id`
     * @returns Response from server
     */
    removeSnap(photoId): Observable<any> {
        if (!photoId) {
            return of('Invalid params');
        }
        const json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: 'remove_pic_info',
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    app_id: this.valuesService.connectAppId,
                    device_id: this.valuesService.connectDeviceId
                },
                ids: [photoId]
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.antitheftMgmtService,
            json,
            'POST'
        ).pipe(
            map(
                (resp: any) => {
                    return resp.result;
                }
            ),
            catchError((error) => {
                throw error;
            })
        );
    }


  /**
   * Gets the antitheft status for all device types (BMS, BIS, CL)
   * @param {object} Contains mandatory `device_id`, `app_id`
   * @returns Response from server
   */
    checkCommandSettings(deviceId, appId): Observable<any> {
        if (!tv4.validate(deviceId, schemas.schemaUuid) || !appId) {
            return of('Invalid params');
        }

        const json = {
            id: parseInt((Math.random() * 100000).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: 'get_one',
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                connect_destination: {
                    device_id: deviceId,
                    app_id: appId
                },

                setting_name: 'antitheft_status'
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.settingsMgmtService,
            json,
            'POST'
        ).pipe(
            map(
                (resp: any) => {
                    return resp.result;
                }
            ),
            catchError((error) => {
                throw error;
            })
        );
    }

    /**
     * Initiates a locate on the device by emitting the device_locate command. Upon receiving this command,
     * the device will perform a GPS locate and send back the location data if possible
     * @param {object} Contains mandatory `app_id` and `device_id`
     * @returns Response from server
     */
    locate(deviceId, appId): Observable<any> {
        if (!tv4.validate(deviceId, schemas.schemaUuid) || !appId) {
            return of('Invalid params');
        }

        const json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: 'locate',
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    app_id: this.valuesService.connectAppId,
                    device_id: this.valuesService.connectAppId
                },
                connect_destination: {
                    app_id: appId,
                    device_id: deviceId
                }
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.antitheftMgmtService,
            json,
            'POST'
        ).pipe(
            map(
                (resp: any) => {
                    return resp.result;
                }
            ),
            catchError((error) => {
                throw error;
            })
        );
    }

    /**
   * This method returns the last known location of the device and the timestamp when that location was received
   * @param {object} Contains mandatory `device_id` and `app_id`
   * @returns Response from server
   */
    getLocation(deviceId, appId): Observable<any> {
        if (!tv4.validate(deviceId, schemas.schemaUuid) || !appId) {
            return of('Invalid params');
        }

        const json = {
        id: parseInt((Math.random() * 100).toString(), 10),
        jsonrpc: this.valuesService.jsonrpc,
        method: 'get_location',
        params: {
            connect_source: {
                user_token: this.cookieService.get(this.valuesService.cookieToken),
                app_id: this.valuesService.connectAppId,
                device_id: this.valuesService.connectDeviceId
            },
            connect_destination: {
                app_id: appId,
                device_id: deviceId
            }
        }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.antitheftMgmtService,
            json,
            'POST'
        ).pipe(
            map(
                (resp: any) => {
                    return resp.result;
                }
            ),
            catchError((error) => {
                throw error;
            })
        );
    }

    /**
   * Sends a message to the device, to be displayed on screen. This will trigger the device_message command
   * @param {object} Contains mandatory `params.deviceId`, `params.appId`, `params.message`, `params.alert`
   * @returns Response from server
   */
    sendMessage(params): Observable<any> {
        if (!tv4.validate(params, schemas.schemaSendMessageAlert)) {
            return of('Invalid params');
        }

        const json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: 'send_message',
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    app_id: this.valuesService.connectAppId,
                    device_id: this.valuesService.connectDeviceId
                },
                connect_destination: {
                    app_id: params.appId,
                    device_id: params.deviceId
                },
                message: params.message,
                alert: params.alert // 0/1
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.antitheftMgmtService,
            json,
            'POST'
        ).pipe(
            map(
                (resp: any) => {
                    return resp.result;
                }
            ),
            catchError((error) => {
                throw error;
            })
        );
    }

    /**
   * Initiates a wipe on the device by sending the device_wipe command
   * @param {object} Contains mandatory `deviceId`, `appId`
   * @returns Response from server
   */
    wipe(deviceId, appId): Observable<any> {
        if (!tv4.validate(deviceId, schemas.schemaUuid) || !appId) {
            return of('Invalid params');
        }

        const json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: 'wipe',
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    app_id: this.valuesService.connectAppId,
                    device_id: this.valuesService.connectDeviceId
                },
                connect_destination: {
                    app_id: appId,
                    device_id: deviceId
                }
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.antitheftMgmtService,
            json,
            'POST'
        ).pipe(
            map(
                (resp: any) => {
                    return resp.result;
                }
            ),
            catchError((error) => {
                throw error;
            })
        );
    }

    /**
   * Initiates a lock on the device by sending the device_lock command
   * @param {object} Contains mandatory `params.deviceId`, `params.appId`, `params.pin`, `params.snap`
   * @returns Response from server
   */
    lock(params): Observable<any>  {
        if (!tv4.validate(params, schemas.schemaSendLock)) {
            return of('Invalid params');
        }

        const json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: 'lock',
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    app_id: this.valuesService.connectAppId,
                    device_id: this.valuesService.connectDeviceId
                },
                connect_destination: {
                    app_id: params.appId,
                    device_id: params.deviceId
                },
                lock_sequence: params.pin,
                // snap_photo: params.snap
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.antitheftMgmtService,
            json,
            'POST'
        ).pipe(
            map(
                (resp: any) => {
                    return resp.result;
                }
            ),
            catchError((error) => {
                throw error;
            })
        );
    }

    /**
   * This will return the PIN and also the state (locked/unlocked) status including the error if available
   * @param {object} Contains mandatory `deviceId` and `appId`
   * @returns Response from server
   */
    getLockStatus(deviceId, appId): Observable<any> {
        if (!tv4.validate(deviceId, schemas.schemaUuid) || !appId) {
            return of('Invalid params');
        }
        const json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: 'get_lock_status',
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    app_id: this.valuesService.connectAppId,
                    device_id: this.valuesService.connectDeviceId
                },
                connect_destination: {
                    app_id: appId,
                    device_id: deviceId
                }
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.antitheftMgmtService,
            json,
            'POST'
        ).pipe(
            map(
                (resp: any) => {
                    return resp.result;
                }
            ),
            catchError((error) => {
                throw error;
            })
        );
    }

}
