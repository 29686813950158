// External
import { Injectable } from "@angular/core";
import { Observable, of, throwError } from 'rxjs';
import { tv4 } from "tv4";
import { map, catchError } from "rxjs/operators";
import { CookieService } from "ngx-cookie-service";
import { Router, ActivatedRoute } from "@angular/router";

// Internal
import { RequestsService } from "../../global/request-service/requests.service";
import { schemas } from "src/app/common/models/schemas";
import { ValuesService } from '../../../../common/values/values.service';
import { UtilsCommonService } from "src/app/common/utils/utils-common.service";
import { ConfigService } from "src/app/common/config/config.service";
import { ProfilesService } from '../../process/profiles/profiles.service';

@Injectable({
    providedIn: "root"
})
export class ParentalControlWebService {
    constructor(
        private requestsService: RequestsService,
        private valuesService: ValuesService,
        private configService: ConfigService,
        private cookieService: CookieService,
        private router: Router,
        private profilesService: ProfilesService,
        private route: ActivatedRoute
    ) { }

    listWebSettings(profile_hash): Observable<any> {

        let profiles = this.profilesService.getParentalProfiles();
        let profile_id = profiles[profile_hash].profile_id;

        if (!tv4.validate(profile_id, schemas.schemaUuid)) {
            console.error("Invalid params", tv4.error);
            return of("Invalid params");
        }

        const json = {
            id: parseInt((Math.random() * 100000).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "get",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                connect_destination: {
                    device_id: this.valuesService.parentalControl.device_id,
                    app_id: this.valuesService.parentalControl.app_id
                },
                profile_id: profile_id
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer, 
            this.valuesService.parentalcontrolWebSettingsService, 
            json, 
            "POST"
        ).pipe(
            map((resp) => {
                return resp.result;
            }),
            catchError((error) => {
                if (error.redirect500) {
                    return this.router.navigate(['/500'], { skipLocationChange: true });
                } else {
                    return throwError(error);
                }
            })
        );

    };

    setWebSettings(info): Observable<any> {

        const json = {
            id: parseInt((Math.random() * 100000).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "edit",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                connect_destination: {
                    device_id: this.valuesService.parentalControl.device_id,
                    app_id: this.valuesService.parentalControl.app_id
                },
                profile_id: info.id
            }
        };

        if (info.hasOwnProperty("categories")) {
            json["params"]["categories"] = info["categories"];
        }

        if (info.hasOwnProperty("state_feature")) {
            json["params"]["state_feature"] = info["state_feature"];
        }

        if (info.hasOwnProperty("restrictions")) {
            json["params"]["restrictions"] = info["restrictions"];
        }

        return this.requestsService.make(
            this.configService.config.nimbusServer, 
            this.valuesService.parentalcontrolWebSettingsService, 
            json, 
            "POST"
        ).pipe(
            map((resp) => {
                return resp.result;
            }),
            catchError((error) => {
                if (error.redirect500) {
                    return this.router.navigate(['/500'], { skipLocationChange: true });
                } else {
                    return throwError(error);
                }
            })
        );

    };

}