export interface Threat {
    app_id: string,
    blocked: number,
    date: number
    device_id: string,
    object: any,
    object_type: string
    router_id: string,
    threat: string,
    threat_subtype: string
    threat_type: string
    timestamp: number,
    fileName: string,
    _id: string
};

export interface ThreatInterface {
    text: object,

    threatType   : string,
    threatSubtype: string,
    objectType   : string,
    object       : any,

    ip           : string,
    lanInitiated : string,
    srcIp        : string,
    protocol     : string,
    port         : string,
    extPort      : string,
    malware      : string,
    fileName     : string,
    url          : string,

    device: {
        boxName: string,
        name: string,
        protectionStatus: number,
        icon: string,
        onBox: boolean,
    },
    date: {
        timestamp: number
    },

    appOrFile?: string
}

export enum ThreatTypes {
    DANGEROUS = 'dangerous',
    MALWARE = 'malware',
    PHISHING = 'phishing',
    FRAUD = 'fraud',
    PUA = 'pua',
    RANSOMWARE = 'ransomware',
    CERTIFICATE = 'certificate',
    UNTRUSTED = 'untrusted',
    EXPLOIT = 'exploit',
    ATTACK = 'attack',
    PRIVACY = 'privacy',
    ANOMALY = 'anomaly',
    BOT = 'bot',
    SPAM = 'spam',
    MINER = 'miner',
    TROJAN = 'trojan',
    MALICIOUS_HOST = 'malicious_host',
    URL = 'url',
    APP = 'app'
}

type ThreatsSubCounter = {
    [key in ThreatTypes]: number
}

export type ThreatsCounter = {[key in ThreatTypes]: ThreatsSubCounter} & {date: string};

export interface ThreatsGetDetailsRequestParameters {
    count: number,
    to_date: number
    from_date: number,
    offset?: number
}
