// External
import { Injectable } from "@angular/core";
import { Observable, of, BehaviorSubject, forkJoin, throwError  } from "rxjs";
import { catchError, map, skipWhile } from 'rxjs/operators';
import { TranslateService } from "@ngx-translate/core";

//Internal
import { ParentalReportsMgmtService } from "../../requests/parentalcontrol-reports/parentalcontrol-reports.service";
import { ParentalSchedulesMgmtService } from "../../requests/parentaladvisor-schedules/parentaladvisor-schedules.service";
import { ParentalRestrictionsMgmtService } from "../../requests/parentaladvisor-restrictions/parentaladvisor-restrictions.service";
import { ReportPerHour, ReportPerDay } from '../../../../common/models/ParentalHourlyReport.model';
import { ProfilesService } from "../../../../common/services/process/profiles/profiles.service";
import { UsefulService } from "../../../../common/services/global/useful/useful.service";
import { ParentalHelperService } from "../../../../pages/parental/parental.helper.service";
import { ParentalValuesService } from "../../../../common/values/parental.values.service";
import { ValuesService } from "../../../../common/values/values.service";
import { ParentalcontrolSettingsService } from "../../requests/parentalcontrol-settings/parentalcontrol-settings.service";

export enum ServiceState {
    WAITING = 'waiting',
    INPROGRESS = 'in_progress',
    DONE = 'done'
}
import { ParentalAppsMgmtService } from "../../requests/parentalcontrol-apps/parentalcontrol-apps.service";
import { ParentalcontrolContactsService } from "../../requests/parentalcontrol-contacts/parentalcontrol-contacts.service";
import { ParentalAdvisorAreasService } from "../../requests/parentaladvisor-areas/parentaladvisor-areas.service";
import { ParentalcontrolLocationService } from "../../requests/parentalcontrol-location/parentalcontrol-location.service";
import { ParentalControlWebService } from '../../requests/parentalcontrol-web/parentalcontrol-web.component';
import { ConnectUserInfoService } from "../../requests/connect-user-info-service/connect-user-info-service";
import { DevicesService } from "../devices/devices.service";
import { AppsConfigService } from "src/app/common/config/apps.config.service";

@Injectable({
    providedIn: 'root'
})
export class ParentalService {

    lastIndex: number;
    scheduleWhatCanYouDo: any = {};
    dtlWhatCanYouDo: number;

    settingsAccount: any = {};
    markToUpdateSetting = true;

    parentalDashboardState = {
        profileIndex: 0,
        dashboardSection: '', // this.parentalValuesService.omniture.sections.dashboard.today
    };

    subsections = JSON.parse(JSON.stringify(this.parentalValuesService.subsections));

    dword1 = [
        // First DWORD
        {_id: this.usefulService.uuidV4(), name: 'webproxy',           bitShift: 0,    number: 1,  ageCategory: [1,2,3,4]},
        {_id: this.usefulService.uuidV4(), name: 'games',              bitShift: 1,    number: 1,  ageCategory: [1,2,3]},
        {_id: this.usefulService.uuidV4(), name: 'tabloids',           bitShift: 2,    number: 1,  ageCategory: [1,2,3,4]},
        {_id: this.usefulService.uuidV4(), name: 'hate',               bitShift: 3,    number: 1,  ageCategory: [1,2,3,4,5]},
        {_id: this.usefulService.uuidV4(), name: 'gambling',           bitShift: 4,    number: 1,  ageCategory: [1,2,3,4,5]},
        {_id: this.usefulService.uuidV4(), name: 'drugs',              bitShift: 5,    number: 1,  ageCategory: [1,2,3,4,5]},
        {_id: this.usefulService.uuidV4(), name: 'illegal',            bitShift: 6,    number: 1,  ageCategory: [1,2,3,4,5]},
        {_id: this.usefulService.uuidV4(), name: 'onlineshop',         bitShift: 7,    number: 1,  ageCategory: [1,2,3,4]},
        {_id: this.usefulService.uuidV4(), name: 'onlinepay',          bitShift: 8,    number: 1,  ageCategory: [1,2,3,4]},
        {_id: this.usefulService.uuidV4(), name: 'socialnetworks',     bitShift: 10,   number: 1,  ageCategory: [1,2,3]},
        {_id: this.usefulService.uuidV4(), name: 'onlinedating',       bitShift: 11,   number: 1,  ageCategory: [1,2,3,4,5]},
        {_id: this.usefulService.uuidV4(), name: 'im',                 bitShift: 12,   number: 1,  ageCategory: [1,2]},
        {_id: this.usefulService.uuidV4(), name: 'searchengines',      bitShift: 13,   number: 1,  ageCategory: [1]},
        ///{_id: this.usefulService.uuidV4(), name: 'regionaltlds',       bitShift: 14,   number: 1,  ageCategory: [0]},
        {_id: this.usefulService.uuidV4(), name: 'news',               bitShift: 15,   number: 1,  ageCategory: [1,2,3]},
        {_id: this.usefulService.uuidV4(), name: 'porn',               bitShift: 16,   number: 1,  ageCategory: [1,2,3,4,5]},
        {_id: this.usefulService.uuidV4(), name: 'maturecontent',      bitShift: 20,   number: 1,  ageCategory: [1,2,3,4,5]},
        {_id: this.usefulService.uuidV4(), name: 'religion',           bitShift: 21,   number: 1,  ageCategory: [1,2,3]},
        {_id: this.usefulService.uuidV4(), name: 'suicide',            bitShift: 22,   number: 1,  ageCategory: [1,2,3,4,5]},
        {_id: this.usefulService.uuidV4(), name: 'health',             bitShift: 23,   number: 1,  ageCategory: [1]},
        //{_id: this.usefulService.uuidV4(), name: 'violentcartoons',    bitShift: 24,   number: 1,  ageCategory: [1,2,3,4,5]},
        {_id: this.usefulService.uuidV4(), name: 'blogs',              bitShift: 25,   number: 1,  ageCategory: [1]},
        {_id: this.usefulService.uuidV4(), name: 'filesharing',        bitShift: 26,   number: 1,  ageCategory: [1,2,3,4]},
        {_id: this.usefulService.uuidV4(), name: 'weapons',            bitShift: 27,   number: 1,  ageCategory: [1,2,3,4,5]},
        {_id: this.usefulService.uuidV4(), name: 'hacking',            bitShift: 28,   number: 1,  ageCategory: [1,2,3,4,5]},
        //{_id: this.usefulService.uuidV4(), name: 'scams',              bitShift: 29,   number: 1,  ageCategory: [1,2,3,4]},
        //{_id: this.usefulService.uuidV4(), name: 'casualgames',        bitShift: 30,   number: 1,  ageCategory: [1,2,3]},
        //{_id: this.usefulService.uuidV4(), name: 'onlinegames',        bitShift: 31,   number: 1,  ageCategory: [0]},
    ];

    dword2 = [
        // Second DWORD
        //{_id: this.usefulService.uuidV4(), name: 'computergames',           bitShift: 0,    number: 2,  ageCategory: [1,2]},
        {_id: this.usefulService.uuidV4(), name: 'narcotics',               bitShift: 1,    number: 2,  ageCategory: [1,2,3,4,5]},
        {_id: this.usefulService.uuidV4(), name: 'videos',                  bitShift: 2,    number: 2,  ageCategory: [1,2,3]},
        {_id: this.usefulService.uuidV4(), name: 'onlinephotos',            bitShift: 3,    number: 2,  ageCategory: [1,2,3]},
        {_id: this.usefulService.uuidV4(), name: 'hobbies',                 bitShift: 4,    number: 2,  ageCategory: [1]},
        {_id: this.usefulService.uuidV4(), name: 'computersandsoftware',    bitShift: 5,    number: 2,  ageCategory: [1,2]},
        {_id: this.usefulService.uuidV4(), name: 'radiomusic',              bitShift: 6,    number: 2,  ageCategory: [1,2]},
        {_id: this.usefulService.uuidV4(), name: 'webmail',                 bitShift: 7,    number: 2,  ageCategory: [1,2]},
        {_id: this.usefulService.uuidV4(), name: 'timewasters',             bitShift: 8,    number: 2,  ageCategory: [1,2,3,4]},
        {_id: this.usefulService.uuidV4(), name: 'portals',                 bitShift: 9,    number: 2,  ageCategory: [1]},
        {_id: this.usefulService.uuidV4(), name: 'sports',                  bitShift: 10,   number: 2,  ageCategory: [1,2]},
        {_id: this.usefulService.uuidV4(), name: 'jobsearch',               bitShift: 11,   number: 2,  ageCategory: [1]},
        {_id: this.usefulService.uuidV4(), name: 'travel',                  bitShift: 12,   number: 2,  ageCategory: [1,2]},
        {_id: this.usefulService.uuidV4(), name: 'business',                bitShift: 13,   number: 2,  ageCategory: [1,2]},
        {_id: this.usefulService.uuidV4(), name: 'education',               bitShift: 14,   number: 2,  ageCategory: [0]},
        {_id: this.usefulService.uuidV4(), name: 'advice',                  bitShift: 15,   number: 2,  ageCategory: [1,2,3]},
        {_id: this.usefulService.uuidV4(), name: 'entertainment',           bitShift: 16,   number: 2,  ageCategory: [1,2,3]},
        {_id: this.usefulService.uuidV4(), name: 'hosting',                 bitShift: 17,   number: 2,  ageCategory: [1,2,3,4]},
        {_id: this.usefulService.uuidV4(), name: 'ads',                     bitShift: 18,   number: 2,  ageCategory: [1,2,3,4]},
        {_id: this.usefulService.uuidV4(), name: 'government',              bitShift: 19,   number: 2,  ageCategory: [1,2]},
        {_id: this.usefulService.uuidV4(), name: 'financial',               bitShift: 20,   number: 2,  ageCategory: [1,2,3]}
    ];


    private readonly onlistHourlyST: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private readonly onlistDailyST: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private readonly onlistDTL: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private readonly onlistSchedule: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private readonly onListApps: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private readonly onListAppsUsage: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private readonly onListContacts: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private readonly onListLatestAddedContacts: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private readonly onListAreas: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private readonly onListWebSettings: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private readonly onSettingsLocation: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private readonly onInfoAccountSettings: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private readonly onlistDailyWebUsage: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);


    constructor(
        private readonly parentalContactsMmtService: ParentalcontrolContactsService,
        private readonly parentalHelperService: ParentalHelperService,
        private readonly parentalReportsMgmtService: ParentalReportsMgmtService,
        private readonly parentalRestrictionsMgmtService: ParentalRestrictionsMgmtService,
        private readonly parentalSchedulesMgmtgService: ParentalSchedulesMgmtService,
        private readonly parentalValuesService: ParentalValuesService,
        private readonly profilesService: ProfilesService,
        private readonly parentalcontrolSettingsService: ParentalcontrolSettingsService,
        private readonly parentalAdvisorAreasService: ParentalAdvisorAreasService,
        private readonly parentalControlWebServiceAreasService: ParentalControlWebService,
        private readonly parentalcontrolLocationService: ParentalcontrolLocationService,
        private readonly translate: TranslateService,
        private readonly parentalAppsMgmtService: ParentalAppsMgmtService,
        private readonly valuesService: ValuesService,
        public readonly usefulService: UsefulService,
        private readonly connectUserInfoService: ConnectUserInfoService,
        private readonly devicesService: DevicesService,
        private readonly appsConfigService: AppsConfigService

    ) {
        for(let i = 0; i < this.dword1.length; ++i) {
            if(this.dword1[i].bitShift == 31) {
                this.dword1[i]['shifted'] = 1 << 30;
                this.dword1[i]['shifted'] *= 2;
            }else{
                this.dword1[i]['shifted'] = 1 << this.dword1[i].bitShift >>> 0;
            }
        }

        for(let z = 0; z < this.dword2.length; ++z) {
            if(this.dword2[z].bitShift == 31) {
                this.dword2[z]['shifted'] = 1 << 30;
                this.dword2[z]['shifted'] *= 2;
            }else{
                this.dword2[z]['shifted'] = 1 << this.dword2[z].bitShift >>> 0;
            }
        }
     }

    requestTime: any = {};

    checkFields(fields, tab, profileHash): boolean {
        const profiles = this.profilesService.getParentalProfiles();
        for (const field of fields) {
            if (tab === "hourly") {
                if (!this.usefulService.getNested(profiles, false, profileHash, field, "hourly")) {
                    return false;
                }
            } else if (tab === "daily"){
                if (!this.usefulService.getNested(profiles, false, profileHash, field, "daily", "seven")) {
                    return false;
                }
            } else if (tab === "apps") {
                if (!this.usefulService.getNested(profiles, false, profileHash, "apps", field)) {
                    return false;
                }
            }
        }
        return true;
    }

    fieldsEx = {
        dashboard : ["screentime", "apps", "web", "contacts"],
        schedule  : ["screentime"],
        screentime: ["screentime", 'screentime_devices'],
        location  : ["location"]
    }

    listHourlyExceptions: any = {
        dashboard: (profileHash) => {
            return this.listHourlyExceptionsExt({fields: 'dashboard'}, profileHash);
        },
        schedule: (profileHash) => {
            return this.listHourlyExceptionsExt({fields: 'schedule'}, profileHash);
        },
        screentime: (profileHash) => {
            return this.listHourlyExceptionsExt({fields: 'screentime'}, profileHash);
        },
        location: (profileHash) => {
            return this.listHourlyExceptionsExt({fields: 'location'}, profileHash);
        }
    }

    setParentalDashboardState(profileIndex, dashboardSection = this.parentalValuesService.omniture.sections.dashboard.today) {
        this.parentalDashboardState.profileIndex = profileIndex;
        this.parentalDashboardState.dashboardSection = dashboardSection;
    }

    getParentalDashboardProfileIndex() {
        return this.parentalDashboardState.profileIndex;
    }

    getParentalDashboardSection() {
        return this.parentalDashboardState.dashboardSection;
    }

    isParentalDashboardSectionToday() {
        return this.parentalDashboardState.dashboardSection === this.parentalValuesService.omniture.sections.dashboard.today;
    }

    getMissingFields(param, profileHash) {
        const fields       = [];
        const profiles     = this.profilesService.getParentalProfiles();
        const allFields    = this.fieldsEx[param];

        if (allFields) {
            for (let i= 0; i < allFields.length; i++) {
                let checkFlag = undefined;

                if (allFields[i] == 'apps' || allFields[i] == 'location' || allFields[i] == 'contacts') {
                    checkFlag = this.usefulService.getNested(profiles, undefined, profileHash, "hourly", "markToUpdate", allFields[i]);
                } else {
                    checkFlag = this.usefulService.getNested(profiles, undefined, profileHash, allFields[i], "hourly", "markToUpdate");
                }

                if (checkFlag == undefined || checkFlag) {
                    fields.push(allFields[i]);
                }
            }
        }
        return fields;
    }

    showSubsection(cardOses) {
        for (const os of cardOses) {
            if (this.appsConfigService.showAppForOs(this.valuesService.appPA, os)) {
                return true;
            }
        }
        return false;
    }

    showSubsections() {
        this.subsections[this.valuesService.centralPaths.parental.applications.id].show = this.showSubsection(this.parentalValuesService.cardsOperatingSystems[this.valuesService.centralPaths.parental.applications.id]);
        this.subsections[this.valuesService.centralPaths.parental.websites.id].show = this.showSubsection(this.parentalValuesService.cardsOperatingSystems[this.valuesService.centralPaths.parental.websites.id]);
        this.subsections[this.valuesService.centralPaths.parental.contacts.id].show = this.showSubsection(this.parentalValuesService.cardsOperatingSystems[this.valuesService.centralPaths.parental.contacts.id]);
        this.subsections[this.valuesService.centralPaths.parental.location.id].show = this.showSubsection(this.parentalValuesService.cardsOperatingSystems[this.valuesService.centralPaths.parental.location.id]);
        this.subsections[this.valuesService.centralPaths.parental.screentime.id].show = this.showSubsection(this.parentalValuesService.cardsOperatingSystems[this.valuesService.centralPaths.parental.screentime.id]);
    }

    showApplication() {
        return this.subsections[this.valuesService.centralPaths.parental.applications.id].show;
    }

    showWebsites() {
        return this.subsections[this.valuesService.centralPaths.parental.websites.id].show;
    }

    showContacts() {
        return this.subsections[this.valuesService.centralPaths.parental.contacts.id].show;
    }

    showLocation() {
        return this.subsections[this.valuesService.centralPaths.parental.location.id].show;
    }

    showScreentime() {
        return this.subsections[this.valuesService.centralPaths.parental.screentime.id].show;
    }

    listWebUsage(profileHash) {
        const profiles = this.profilesService.getParentalProfiles();

        const info = {
            profile_id: profiles[profileHash].profile_id
        }

        const checkFlag = this.usefulService.getNested(profiles, undefined, profileHash, "websites", "daily", 'seven', "markToUpdate");

        if (checkFlag !== undefined && !checkFlag) {
            return of(profiles);
        }

        if (this.onlistDailyWebUsage.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onlistDailyWebUsage.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        } else {
            this.onlistDailyWebUsage.next(this.valuesService.processServiceState.INPROGRESS);
            return this.parentalReportsMgmtService.getWebUsage(info)
            .pipe(
                map(
                    resp => {
                        if (resp) {

                            let ret = false;
                            if (!this.usefulService.checkNested(profiles, profileHash)) {
                                ret = this.usefulService.setNested(profiles, {}, profileHash);
                            } else {
                                ret = true;
                            }
                            if (ret) {

                                if(!this.usefulService.getNested(profiles, false, profileHash, 'websites')){
                                    profiles[profileHash]['websites'] = {};
                                }

                                const dailyInfo = this.usefulService.getNested(profiles, false, profileHash, 'websites', "daily")
                                if (!dailyInfo) {
                                    profiles[profileHash]['websites']['daily'] = {};
                                }
                                profiles[profileHash]['websites']['daily']['seven'] = resp;


                                if (!this.usefulService.getNested(profiles, false, profileHash, "daily")) {
                                    profiles[profileHash]["daily"] = {};
                                    profiles[profileHash]["daily"]['seven'] = {};
                                } else if (!this.usefulService.getNested(profiles, false, profileHash, "daily", 'seven')) {
                                    profiles[profileHash]["daily"]['seven'] = {};
                                }
                                profiles[profileHash]["daily"]['seven']['websites'] = resp;
                            }


                            profiles[profileHash]["websites"]["daily"]['seven'].markToUpdate = false;
                            this.onlistDailyWebUsage.next(this.valuesService.processServiceState.DONE);
                        } else {
                            profiles[profileHash]["websites"]["daily"]['seven'].markToUpdate = true;
                            this.onlistDailyWebUsage.next(this.valuesService.processServiceState.DONE);
                        }
                    }
                ),
                catchError( (err)=> {

                    this.onlistDailyWebUsage.next(this.valuesService.processServiceState.DONE);
                    profiles[profileHash]["screentime"]["daily"].markToUpdate = true;
                    this.onlistDailyWebUsage.next(this.valuesService.processServiceState.DONE);
                    throw err;
                })
            );
        }
    }

    listHourlyExceptionsExt(info, profileHash): Observable<any> {
        const profiles     = this.profilesService.getParentalProfiles();

        // logica de aici functioneaza doar daca ingo.fields e un array si are si elemente in el
        // cu alte cuvinte, "listHourlyExceptionsExt" ar trebui mereu apelata cu un parametru ce
        // este cheie in obiectul "fieldsEx", iar valoarea sa din acel obiect e un array ce contine elemente (lungime > 0)
        const fields = this.getMissingFields(info.fields, profileHash);
        if (fields.length == 0) {
            return of(profiles);
        }

        info.profile_id = this.usefulService.getNested(profiles, undefined, profileHash, "profile_id");
        info.fields  = fields;

        if (this.onlistHourlyST.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onlistHourlyST.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        } else {
            this.onlistHourlyST.next(this.valuesService.processServiceState.INPROGRESS);
            return this.parentalReportsMgmtService.getHourlyActivity(info)
                .pipe(
                    map(
                        resp => {
                            if (resp) {

                                if (!profiles[profileHash]) {
                                    profiles[profileHash] = {};
                                }

                                Object.keys(resp).forEach(key => {

                                    if (key === 'offset') {
                                        profiles[profileHash][key] = resp[key];
                                    } else {
                                        // !!! campurile astea sunt mutate de simona, poate ar trebui sa nu mai mutam campuri daca nu le mutam bine
                                        if (key == "contacts" || key == "apps" || key == "location") {

                                            const hourly = this.usefulService.getNested(profiles, false, profileHash, "hourly");
                                            if (!hourly) {
                                                profiles[profileHash]["hourly"] = {};
                                                profiles[profileHash]["hourly"]['markToUpdate'] = {};
                                            }

                                            profiles[profileHash]["hourly"][key] = resp[key];
                                            profiles[profileHash]["hourly"]['markToUpdate'][key] = false;
                                        } else {

                                            if (!profiles[profileHash][key]) {
                                                profiles[profileHash][key] = {};
                                            }

                                            profiles[profileHash][key]['hourly'] = resp[key];
                                            profiles[profileHash][key]["hourly"]['markToUpdate'] = false;
                                        }
                                    }
                                });
                            }
                            this.setRequestTime();
                            this.onlistHourlyST.next(this.valuesService.processServiceState.DONE);
                        }
                    ),
                    catchError( (err)=> {
                        this.onlistHourlyST.next(this.valuesService.processServiceState.DONE);
                        throw err;
                    })
                );
        }
    }


    listDailyActivityExceptions = {
        dashboard_7: (profileHash) => {
            return this.listDailyActivityExceptionsExt({fields: 'dashboard', "noDays": 7}, profileHash);
        },
        screentime_7: (profileHash) => {
            return this.listDailyActivityExceptionsExt({fields: 'screentime', "noDays": 7}, profileHash);
        },
        websites_7: (profileHash) => {
            return this.listDailyActivityExceptionsExt({fields: 'websites', "noDays": 7}, profileHash);
        },
        screentime_30: (profileHash) => {
            return this.listDailyActivityExceptionsExt({fields: 'screentime', "noDays": 30}, profileHash);
        },
    }

    fieldsEx2 = {
        dashboard : ["screentime", "apps", "web", "contacts"],
        schedule  : ["screentime"],
        screentime: ["screentime"],
        websites: ["web"]
    }

    listDailyActivityExceptionsExt(info, profileHash): Observable<any> {
        const days = (info.noDays === 7) ? 'seven' : 'thirty';
        const profiles = this.profilesService.getParentalProfiles();

        info.profile_id = profiles[profileHash].profile_id;

        const checkFlag = this.usefulService.getNested(profiles, undefined, profileHash, "screentime", "daily", days, "markToUpdate");
        if (info.noDays === 7) {
            if (checkFlag !== undefined && !checkFlag && this.checkFields(this.fieldsEx2[info.fields], "daily", profileHash)) {
                return of(profiles);
            }
        } else {
            if (checkFlag !== undefined && !checkFlag) {
                return of(profiles);
            }
        }

        if (this.onlistDailyST.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onlistDailyST.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        } else {
            this.onlistDailyST.next(this.valuesService.processServiceState.INPROGRESS);
            return this.parentalReportsMgmtService.getDailyActivity(info)
            .pipe(
                map(
                    resp => {
                        if (resp) {
                            let ret = false;
                            if (!this.usefulService.checkNested(profiles, profileHash)) {
                                ret = this.usefulService.setNested(profiles, {}, profileHash);
                            } else {
                                ret = true;
                            }
                            if (ret) {
                                Object.keys(resp).map((key) => {
                                    if (!profiles[profileHash][key]) {
                                        profiles[profileHash][key] = {};
                                    }
                                    if (key === 'offset') {
                                        profiles[profileHash][key] = resp[key];
                                    } else {
                                        const dailyInfo = this.usefulService.getNested(profiles, false, profileHash, key, "daily")
                                        if (!dailyInfo) {
                                            profiles[profileHash][key]['daily'] = {};
                                        }
                                        profiles[profileHash][key]['daily'][days] = resp[key];
                                    }

                                    if (key == "contacts" || key == "apps") {
                                        if (!this.usefulService.getNested(profiles, false, profileHash, "daily")) {
                                            profiles[profileHash]["daily"] = {};
                                            profiles[profileHash]["daily"][days] = {};
                                        } else if (!this.usefulService.getNested(profiles, false, profileHash, "daily", days)) {
                                            profiles[profileHash]["daily"][days] = {};
                                        }
                                        profiles[profileHash]["daily"][days][key] = resp[key];
                                    }
                                });
                            }
                            profiles[profileHash]["screentime"]["daily"][days].markToUpdate = false;
                            this.onlistDailyST.next(this.valuesService.processServiceState.DONE);
                        } else {
                            profiles[profileHash]["screentime"]["daily"][days].markToUpdate = true;
                            this.onlistDailyST.next(this.valuesService.processServiceState.DONE);
                        }
                    }
                ),
                catchError( (err)=> {
                    this.onlistDailyST.next(this.valuesService.processServiceState.DONE);
                    profiles[profileHash]["screentime"]["daily"].markToUpdate = true;
                    this.onlistDailyST.next(this.valuesService.processServiceState.DONE);
                    throw err;
                })
            );
        }
    }

    listSchedules(info, profileHash): Observable<any> {
        const profiles = this.profilesService.getParentalProfiles();
        const checkFlag = this.usefulService.getNested(profiles, undefined, profileHash, "schedules", "markToUpdate");

        if (checkFlag !== undefined && !checkFlag) {
            return of(profiles);
        }

        if (this.onlistSchedule.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onlistSchedule.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        } else {
            this.onlistSchedule.next(this.valuesService.processServiceState.INPROGRESS);
            return this.parentalSchedulesMgmtgService.listSchedules(info.profile_id)
                .pipe(
                    map(
                        resp => {
                            if (resp) {
                                let ret = false;
                                if (!this.usefulService.checkNested(profiles, profileHash)) {
                                    ret = this.usefulService.setNested(profiles, {}, profileHash);
                                } else {
                                    ret = true;
                                }

                                if (ret) {
                                    if (!this.usefulService.getNested(profiles, false, profileHash, "schedules")) {
                                        profiles[profileHash]["schedules"] = {};
                                    }
                                    profiles[profileHash]["schedules"]["values"] = resp.schedules;
                                }
                                profiles[profileHash]["schedules"].markToUpdate = false;
                                this.onlistSchedule.next(this.valuesService.processServiceState.DONE);
                            } else {
                                profiles[profileHash]["schedules"].markToUpdate = true;
                                this.onlistSchedule.next(this.valuesService.processServiceState.DONE);
                            }
                        }
                    ),
                    catchError((err) => {
                        profiles[profileHash]["schedules"] = {};
                        profiles[profileHash]["schedules"].markToUpdate = true;
                        this.onlistSchedule.next(this.valuesService.processServiceState.DONE);
                        throw err;
                    })
                );
        }
    }

    listDTL(info, profileHash): Observable<any> {
        const profiles = this.profilesService.getParentalProfiles();
        const checkFlag = this.usefulService.getNested(profiles, undefined, profileHash, "daily_time_limit", "markToUpdate");

        if(checkFlag !== undefined && !checkFlag){
            return of(profiles);
        }

        if (this.onlistDTL.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onlistDTL.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        } else {
            this.onlistDTL.next(this.valuesService.processServiceState.INPROGRESS);
            return this.parentalRestrictionsMgmtService.getDTL(info.profile_id)
                .pipe(
                    map(
                        resp => {
                            if (resp) {
                                let ret = false;
                                if (!this.usefulService.checkNested(profiles, profileHash)) {
                                    ret = this.usefulService.setNested(profiles, {}, profileHash);
                                } else {
                                    ret = true;
                                }

                                if (ret) {
                                    const obj = {};
                                    for (let i = 0; i < resp.length; i++) {
                                        obj[resp[i].restriction_id] = resp[i];
                                    }
                                    if (!this.usefulService.getNested(profiles, false, profileHash, "daily_time_limit")) {
                                        profiles[profileHash]["daily_time_limit"] = {};
                                    }
                                    profiles[profileHash]["daily_time_limit"]["values"] = obj;
                                    this.updateWeekStart(profileHash);
                                }
                                profiles[profileHash]["daily_time_limit"].markToUpdate = false;
                                this.onlistDTL.next(this.valuesService.processServiceState.DONE);
                            } else {
                                profiles[profileHash]["daily_time_limit"].markToUpdate = true;
                                this.onlistDTL.next(this.valuesService.processServiceState.DONE);
                            }
                        }
                    ),
                    catchError((err) => {
                        profiles[profileHash]["daily_time_limit"] = {};
                        profiles[profileHash]["daily_time_limit"].markToUpdate = true;
                        this.onlistDTL.next(this.valuesService.processServiceState.DONE);
                        throw err;
                    })
                );
        }
    }

    listContacts(profileHash): Observable<any> {
        const profiles = this.profilesService.getParentalProfiles();
        const checkFlag = this.usefulService.getNested(profiles, undefined, profileHash, "contacts", "all", "markToUpdate");

        if(checkFlag !== undefined && !checkFlag) {
            return of(profiles);
        }

        if (this.onListContacts.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onListContacts.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        } else {
            this.onListContacts.next(this.valuesService.processServiceState.INPROGRESS);
            return this.parentalContactsMmtService.getContactsList(profileHash)
            .pipe(
                map(
                    resp => {
                        if (!profiles[profileHash]) {
                            profiles[profileHash] = {};
                        }
                        if (!profiles[profileHash]['contacts']) {
                            profiles[profileHash]['contacts'] = {};
                        }
                        if (!profiles[profileHash]['contacts']['all']) {
                            profiles[profileHash]['contacts']['all'] = {};
                        }
                        if (resp) {
                            profiles[profileHash]['contacts']['all']['list'] = resp['list'];
                            profiles[profileHash]['contacts']['all']['stateFeature'] = resp['state_feature'];
                            profiles[profileHash]['contacts']['all']['markToUpdate'] = false;
                            this.onListContacts.next(this.valuesService.processServiceState.DONE);
                        } else {
                            profiles[profileHash]["contacts"]['all']['markToUpdate'] = true;
                            this.onListContacts.next(this.valuesService.processServiceState.DONE);
                        }
                    }
                ),
                catchError( (err)=> {
                    if (!profiles[profileHash]['contacts']) {
                        profiles[profileHash]["contacts"] = {};
                    }
                    if (!profiles[profileHash]['contacts']['all']) {
                        profiles[profileHash]['contacts']['all'] = {};
                    }
                    profiles[profileHash]["contacts"]['all']['markToUpdate'] = true;
                    this.onListContacts.next(this.valuesService.processServiceState.DONE);
                    throw err;
                })
            );
        }
    }

    listLatestAddedContacts(profileHash): Observable<any> {
        const profiles = this.profilesService.getParentalProfiles();
        const checkFlag = this.usefulService.getNested(profiles, undefined, profileHash, "contacts", "latestAdded", "markToUpdate");

        if(checkFlag !== undefined && !checkFlag){
            return of(profiles);
        }

        if (this.onListLatestAddedContacts.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onListLatestAddedContacts.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        } else {
            this.onListLatestAddedContacts.next(this.valuesService.processServiceState.INPROGRESS);
            return this.parentalContactsMmtService.getLatestAddedContactsList(profileHash)
            .pipe(
                map(
                    resp => {
                        if (!profiles[profileHash]) {
                            profiles[profileHash] = {};
                        }
                        if (!profiles[profileHash]['contacts']) {
                            profiles[profileHash]['contacts'] = {};
                        }
                        if (!profiles[profileHash]['contacts']['latestAdded']) {
                            profiles[profileHash]['contacts']['latestAdded'] = {};
                        }
                        if (resp) {
                            profiles[profileHash]['contacts']['latestAdded'] = resp;
                            profiles[profileHash]['contacts']['latestAdded']['markToUpdate'] = false;
                            this.onListLatestAddedContacts.next(this.valuesService.processServiceState.DONE);
                        } else {
                            profiles[profileHash]["contacts"]['latestAdded']['markToUpdate'] = true;
                            this.onListLatestAddedContacts.next(this.valuesService.processServiceState.DONE);
                        }
                    }
                ),
                catchError( (err)=> {
                    if (!profiles[profileHash]['contacts']) {
                        profiles[profileHash]["contacts"] = {};
                    }
                    if (!profiles[profileHash]['contacts']['latestAdded']) {
                        profiles[profileHash]['contacts']['latestAdded'] = {};
                    }
                    profiles[profileHash]["contacts"]['latestAdded']['markToUpdate'] = true;
                    this.onListLatestAddedContacts.next(this.valuesService.processServiceState.DONE);
                    throw err;
                })
            );
        }
    }

    listAreas(profileHash): Observable<any> {
        const profiles = this.profilesService.getParentalProfiles();
        const checkFlag = this.usefulService.getNested(profiles, undefined, profileHash, "areas", "markToUpdate");

        if(checkFlag !== undefined && !checkFlag){
            return of(profiles);
        }

        if (this.onListAreas.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onListAreas.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        } else {
            this.onListAreas.next(this.valuesService.processServiceState.INPROGRESS);
            return this.parentalAdvisorAreasService.getAreasByProfile(profileHash)
            .pipe(
                map(
                    resp => {
                        if (!profiles[profileHash]) {
                            profiles[profileHash] = {};
                        }
                        if (!profiles[profileHash]['areas']) {
                            profiles[profileHash]['areas'] = {};
                        }
                        if (resp) {
                            profiles[profileHash]['areas']['list'] = resp;
                            profiles[profileHash]['areas']['markToUpdate'] = false;
                            this.onListAreas.next(this.valuesService.processServiceState.DONE);
                        } else {
                            profiles[profileHash]['areas']['markToUpdate'] = true;
                            this.onListAreas.next(this.valuesService.processServiceState.DONE);
                        }
                    }
                ),
                catchError( (err)=> {
                    if (!profiles[profileHash]['areas']) {
                        profiles[profileHash]['areas'] = {};
                    }
                    profiles[profileHash]['areas']['markToUpdate'] = true;
                    this.onListAreas.next(this.valuesService.processServiceState.DONE);
                    throw err;
                })
            );
        }
    }

    getLocationSettings(profileHash): Observable<any> {
        const profiles = this.profilesService.getParentalProfiles();
        const checkFlag = this.usefulService.getNested(profiles, undefined, profileHash, "locationSettings", "markToUpdate");

        if(checkFlag !== undefined && !checkFlag){
            return of(profiles);
        }

        if (this.onSettingsLocation.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onSettingsLocation.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        } else {
            this.onSettingsLocation.next(this.valuesService.processServiceState.INPROGRESS);
            return this.parentalcontrolLocationService.getState(profileHash)
            .pipe(
                map(
                    resp => {
                        if (!profiles[profileHash]) {
                            profiles[profileHash] = {};
                        }
                        if (!profiles[profileHash]['locationSettings']) {
                            profiles[profileHash]['locationSettings'] = {};
                        }
                        if (resp) {
                            profiles[profileHash]['locationSettings']['stateFeature'] = resp['state_feature'];
                            profiles[profileHash]['locationSettings']['markToUpdate'] = false;
                            this.onSettingsLocation.next(this.valuesService.processServiceState.DONE);
                        } else {
                            profiles[profileHash]['locationSettings']['markToUpdate'] = true;
                            this.onSettingsLocation.next(this.valuesService.processServiceState.DONE);
                        }
                    }
                ),
                catchError( (err)=> {
                    if (!profiles[profileHash]['locationSettings']) {
                        profiles[profileHash]['locationSettings'] = {};
                    }
                    profiles[profileHash]['locationSettings']['markToUpdate'] = true;
                    this.onSettingsLocation.next(this.valuesService.processServiceState.DONE);
                    throw err;
                })
            );
        }
    }

    listApps: any = {
        applications: (profileHash) => {
            return this.listAppsExt({fields: ['state_feature', 'state_applied']}, profileHash);
        },
        manage_applications: (profileHash) => {
            return this.listAppsExt({fields: ['installed_apps', 'state_feature', 'state_applied']}, profileHash);
        }
    }

    listAppsExt(info, profileHash) {
        if (!info) {
            info = {};
        }
        const profiles = this.profilesService.getParentalProfiles();
        const checkFlag = this.usefulService.getNested(profiles, undefined, profileHash, "apps", "markToUpdate_list");
        if (checkFlag !== undefined && !checkFlag && this.checkFields(info.fields, "apps", profileHash)) {
            return of(profiles);
        }
        info.profile_id = profiles[profileHash].profile_id;

        if (this.onListApps.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onListApps.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        } else {
            this.onListApps.next(this.valuesService.processServiceState.INPROGRESS);
            return this.parentalAppsMgmtService.getApps(info)
            .pipe(
                map((resp) => {
                    if (resp) {
                        let ret = false;
                        if (!this.usefulService.checkNested(profiles, profileHash)) {
                            ret = this.usefulService.setNested(profiles, {}, profileHash);
                        } else {
                            ret = true;
                        }
                        if (ret) {
                            if (!this.usefulService.getNested(profiles, false, profileHash, "apps")) {
                                profiles[profileHash]["apps"] = {};
                            }
                            profiles[profileHash]["apps"]["state_applied"] = resp.state_feature;
                            profiles[profileHash]["apps"]["state_feature"] = resp.state_feature;
                            profiles[profileHash]["apps"]["installed_apps"] = resp.installed_apps;
                            profiles[profileHash]["apps"]["markToUpdate_list"] = false;
                            this.onListApps.next(this.valuesService.processServiceState.DONE);
                        } else {
                            profiles[profileHash]["apps"]["markToUpdate_list"] = true;
                            this.onListApps.next(this.valuesService.processServiceState.DONE);
                        }
                    }
                }),
                catchError( (err)=> {
                    profiles[profileHash]["apps"]["markToUpdate_list"] = true;
                    this.onListApps.next(this.valuesService.processServiceState.DONE);
                    throw err;
                })
            );
        }
    }

    listAppsUsage(info, profileHash) {
        const profiles = this.profilesService.getParentalProfiles();
        const checkFlag = this.usefulService.getNested(profiles, undefined, profileHash, "apps", "markToUpdate_usage");
        if (checkFlag !== undefined && !checkFlag) {
            return of(profiles);
        }
        info["profile_id"] = profiles[profileHash].profile_id;

        if (this.onListAppsUsage.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onListAppsUsage.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        } else {
            this.onListAppsUsage.next(this.valuesService.processServiceState.INPROGRESS);
            return this.parentalAppsMgmtService.getAppsUsage(info)
            .pipe(
                map((resp) => {
                    if (resp) {
                        let ret = false;
                        if (!this.usefulService.checkNested(profiles, profileHash)) {
                            ret = this.usefulService.setNested(profiles, {}, profileHash);
                        } else {
                            ret = true;
                        }
                        if (ret) {
                            if (!this.usefulService.getNested(profiles, false, profileHash, "apps")) {
                                profiles[profileHash]["apps"] = {};
                            }
                            profiles[profileHash]["apps"]["usage"] = resp;
                            profiles[profileHash]["apps"]["markToUpdate_usage"] = false;
                            this.onListAppsUsage.next(this.valuesService.processServiceState.DONE);
                        } else {
                            profiles[profileHash]["apps"]["markToUpdate_usage"] = true;
                            this.onListAppsUsage.next(this.valuesService.processServiceState.DONE);
                        }
                    }
                }),
                catchError( (err)=> {
                    profiles[profileHash]["apps"]["markToUpdate_usage"] = true;
                    this.onListAppsUsage.next(this.valuesService.processServiceState.DONE);
                    throw err;
                })
            );
        }
    }

    setRequestTime() {
        const date = new Date();
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const flagPM = (hours >= 12) ? true : false;
        hours = (hours % 12) ? (hours % 12) : 12;
        const minutesString = (minutes < 10) ? ('0' + minutes) : minutes;
        this.requestTime = {
            "hours": hours,
            "minutes": minutesString,
            "pm": flagPM,
            "timestamp": date.getTime()
        }
    }

    getRequestTime() {
        return this.requestTime;
    }

    get() {
        return this.profilesService.getParentalProfiles();
    };

    updateWeekStart(profileHash) {
        const profiles = this.profilesService.getParentalProfiles();
        const limits = this.usefulService.getNested(profiles, [], profileHash, "daily_time_limit", "values");
        for (const limitId in limits) {
            const days = this.usefulService.getNested(limits[limitId], {}, "days");
            days.push(days[0]);
            days.shift();
        }
    }

    setHourlyReportsArray(tab, type, objectHours, profileHash) {
        const profiles = this.profilesService.getParentalProfiles();
        const hours = this.usefulService.getNested(profiles, undefined, profileHash, tab, type, "hours");
        if (hours) {
            if (hours.length < 24) {
                objectHours.hours = objectHours.hours.slice(24 - hours.length, 24);
                objectHours.schedules = objectHours.schedules.slice(24 - hours.length, 24);
            }
            for (let i = 0; i < objectHours["hours"].length; i++) {
                if (hours[i]) {
                    if (hours[i].schedule_name) {
                        delete hours[i].schedule_name;
                    }
                    if (objectHours["hours"][i] >= 45) {
                        hours[i].schedule_name = objectHours["schedules"][i];
                    }
                }
            }
        }
    }

    getHourlyReportsArray(tab, type, profileHash): ReportPerHour[] {
        const profiles = this.profilesService.getParentalProfiles();
        let reports_array = this.usefulService.getNested(profiles, false, profileHash, tab, type, "hours");

        if (reports_array && reports_array.length < 24) {
            const length = profiles[profileHash][tab][type].hours.length;
            const zeros_array = Array<{}>(24 - length).fill({ "time_spent": 0 });
            reports_array = zeros_array.concat(profiles[profileHash][tab][type].hours);
        }
        return reports_array;
    }

    getProfileStatus(tab, type, profileHash) {
        const profiles = this.profilesService.getParentalProfiles();
        const profile_status = this.usefulService.getNested(profiles, undefined, profileHash, tab, type, "profile_status");
        return profile_status;
    }

    getHourlyTimeInfo(tab, type, profileHash) {
        const profiles = this.profilesService.getParentalProfiles();
        return {
            "time_spent_threshold": this.usefulService.getNested(profiles, undefined, profileHash, tab, type, "time_spent_threshold"),
            "time_spent": this.usefulService.getNested(profiles, undefined, profileHash, tab, type, "time_spent"),
            "daily_limit": this.usefulService.getNested(profiles, undefined, profileHash, tab, type, "daily_time_limit"),
        };
    }

    getHourlyActionsInfo(tab, type, profileHash) {
        const tips = {
            "message_id": undefined,
            "actions": {}
        };
        const profiles = this.profilesService.getParentalProfiles();

        tips["actions"] = this.usefulService.getNested(profiles, undefined, profileHash, tab, type, "actions");
        const messageId = this.usefulService.getNested(profiles, undefined, profileHash, tab, type, "message_id");
        tips["message_id"] = this.parentalValuesService.messagesTodayCard[messageId];
        return tips;
    }

    getReportsArray(profileHash, tab, type, secondType?) {
        const profiles = this.profilesService.getParentalProfiles();

        let reports_array;
        if (secondType) {
            reports_array = this.usefulService.getNested(profiles, undefined, profileHash, tab, type, secondType);
        } else {
            reports_array = this.usefulService.getNested(profiles, undefined, profileHash, tab, type);
        }
        return reports_array;
    }

    getReportsArray_new(id, type, tab, secondType?) {
        const profiles = this.profilesService.getParentalProfiles();
        let reports_array;
        if (secondType) {
            reports_array = this.usefulService.getNested(profiles, [], id, type, secondType, tab);
        } else {
            reports_array = this.usefulService.getNested(profiles, [], id, type, tab);
        }
        return reports_array;
    }

    getRecommendedScheduleToday(tab, type, profileHash): [] {
        const tips = this.getHourlyActionsInfo(tab, type, profileHash);
        const schedule_obj = this.parentalHelperService.timestampToDailyHours(tips["actions"]["add_schedule"]);
        return schedule_obj;
    }

    getRecommendedScheduleMoreDays(tab, type, numberDays, profileHash): [] {
        const tips = this.getDailyActionsInfo(tab, type, numberDays, profileHash);
        const schedule_obj = this.parentalHelperService.timestampToDailyHours(tips["actions"]["add_schedule"]);
        return schedule_obj;
    }

    getSchedules(profileHash): [] {
        const profiles = this.profilesService.getParentalProfiles();
        const schedules = this.usefulService.getNested(profiles, undefined, profileHash, "schedules", "values");
        return schedules;
    }

    getDevices(tab, type, profileHash) {
        const profiles = this.profilesService.getParentalProfiles();
        if (this.usefulService.getNested(profiles, undefined, profileHash, tab, type)) {
            return {
                'display': this.usefulService.getNested(profiles, [], profileHash, tab, type, "to_display"),
                'used_devices': this.usefulService.getNested(profiles, [], profileHash, "used_devices")
            };
        } else {
            return undefined;
        }
    }

    getScheduleFromHash(schedule_hash, profileHash): {} {
        const profiles = this.profilesService.getParentalProfiles();
        const profile = this.usefulService.getNested(profiles, {}, profileHash);
        const schedules = this.usefulService.getNested(profile, null, "schedules", "values");
        for (const schedule of schedules) {
            if (schedule.schedule_hash == schedule_hash) {
                return schedule;
            }
        }
        return {};
    }

    // gets reports for 7/30 days
    getDailyReportsArray(tab, type, textDays, profileHash): ReportPerDay[] {
        const profiles = this.profilesService.getParentalProfiles();
        let days_array = [];
        const data = this.usefulService.getNested(profiles, undefined, profileHash, tab, type, textDays, 'days');
        const numberDays = (textDays === 'seven') ? 7 : 30;

        if (data) {
            if(textDays === 'seven') {
                // se creaza un array cu 7 zile
                days_array = Array<{}>(numberDays).fill({});
                for (let i = 0; i < data.length; i++) {
                    const indexDay = this.parentalHelperService.computeIndexDayFromTimestamp(data[i].timestamp);
                    data[i]['indexDay'] = indexDay;
                    this.lastIndex = indexDay;
                    data[i]['future_day'] = false;
                    days_array[i] = data[i];
                }
                // se adauga in array zilele care lipsesc din saptamana
                // in caz ca profilul a fost creat de o zi, trebuiesc adaugate 6 zile
                if (days_array.length !== data.length) {
                    const number = days_array.length - data.length;
                    for (let i = number; i > 0; i--) {
                        days_array[days_array.length - i] = {};
                        this.lastIndex = this.lastIndex + 1;
                        if (this.lastIndex === numberDays) {
                            this.lastIndex = 0;
                        }
                        days_array[days_array.length - i]['indexDay'] = this.lastIndex;
                        days_array[days_array.length - i]['late_hours'] = false;
                        days_array[days_array.length - i]['future_day'] = true;
                    }
                }
            } else {
                // se creaza un array cu 30 de zile
                days_array = Array<{}>(numberDays).fill({});
                for (let i = 0; i < data.length; i++) {
                    days_array[i] = data[i];
                }
                // se adauga zilele care lipsesc din luna
                if (days_array.length !== data.length) {
                    const number = days_array.length - data.length;
                    for (let i = number; i > 0; i--) {
                        days_array[days_array.length - i] = {};
                        days_array[days_array.length - i]['late_hours'] = false;
                        days_array[days_array.length - i]['future_day'] = true;
                    }
                }
            }
        }
        return (days_array) ? days_array : undefined;
    }

    // actions for 7/30 days
    getDailyActionsInfo(tab, type, textDays, profileHash): {} {
        const tips: any = { "message_id": undefined };
        const profiles = this.profilesService.getParentalProfiles();
        tips["actions"] = this.usefulService.getNested(profiles, undefined, profileHash, tab, type, textDays, "actions");
        tips["message_id"] = this.parentalValuesService.messagesDailyCard[profiles[profileHash][tab][type][textDays].message_id];
        return tips;
    }

    getCategoriesTypeInfo(profileHash, tab, type, category, category_hits, secondType?): {} {
        const all_categories = this.getReportsArray(profileHash, tab, type, secondType);
        return (all_categories && all_categories[category] && all_categories[category_hits])
            ?   {
                'categories': (all_categories[category].length > 0) ? all_categories[category] : undefined,
                'hits': all_categories[category_hits] ? all_categories[category_hits] : undefined,
                'max_hits': (all_categories[category].length > 0) ? all_categories[category][0].hits : null
                }
            : undefined;
    }

    getCategories(categoriesInfo) {
        return (categoriesInfo && categoriesInfo.categories) ? categoriesInfo.categories : undefined;
    }

    updateDTL(info, profileHash): Observable<any> {
        const profiles = this.profilesService.getParentalProfiles();

        return this.parentalRestrictionsMgmtService.updateDtl(info)
            .pipe(
                map(
                    resp => {
                        let ret = false;
                        if (!this.usefulService.checkNested(profiles, profileHash)) {
                            ret = this.usefulService.setNested(profiles, {}, profileHash);
                        } else {
                            ret = true;
                        }

                        if (ret) {
                            const obj = {};
                            for (let i = 0; i < resp.length; i++) {
                                obj[resp[i].restriction_id] = resp[i];
                            }
                            if (!this.usefulService.getNested(profiles, false, profileHash, "daily_time_limit")) {
                                profiles[profileHash]["daily_time_limit"] = {};
                            }
                            profiles[profileHash]["daily_time_limit"]["values"] = obj;
                            this.updateWeekStart(profileHash);
                        }
                    },
                    err => {
                        return of(err);
                    }
                ),
                catchError((err) => {
                    throw err;
                })
            );
    }

    pauseChildProfile(info): Observable<any> {
        return this.parentalSchedulesMgmtgService.pauseProfile(info)
        .pipe(
            map(
                resp => {
                    return of(resp);
                },
                err => {
                }
            )
        );
    }

    updateScreenTime(profile_id, tab) {
        const profiles = this.profilesService.getParentalProfiles();
        const profileHash = this.usefulService.crc32Convert(profile_id);

        const dtl = this.usefulService.getNested(profiles, undefined, profileHash, 'daily_time_limit');
        const screentime_hourly = this.usefulService.getNested(profiles, undefined, profileHash, 'screentime', 'hourly');
        const screentime_daily = this.usefulService.getNested(profiles, undefined, profileHash, 'screentime', 'daily');
        // la screentime_daily sa nu uitam de screentime_daily['seven'].markToUpdate si screentime_daily['thirty'].markToUpdate cand se schimba functia
        const schedules = this.usefulService.getNested(profiles, undefined, profileHash, 'schedules');

        if (tab === "limits") {
            if (dtl) {
                dtl.markToUpdate = true;
            }
            if (screentime_hourly) {
                screentime_hourly.markToUpdate = true;
            }
            if (screentime_daily) {
                screentime_daily.markToUpdate = true;
            }
        } else if (tab === "schedules") {
            if (schedules) {
                schedules.markToUpdate = true;
            }
            if (screentime_hourly) {
                screentime_hourly.markToUpdate = true;
            }
            if (screentime_daily) {
                screentime_daily.markToUpdate = true;
            }
        } else if (tab === "status") {
            // de updatat pentru ca nu cred ca se numeste pause field-ul
            // profiles[profile_hash]["pause"].markToUpdate = true;
            if (screentime_hourly) {
                screentime_hourly.markToUpdate = true;
            }
            if (screentime_daily) {
                screentime_daily.markToUpdate = true;
            }
        } else if (tab === "screentime") {
            // profiles[profile_hash]["pause"].markToUpdate = true;
            if (schedules) {
                schedules.markToUpdate = true;
            }
            if (dtl) {
                dtl.markToUpdate = true;
            }
            if (screentime_hourly) {
                screentime_hourly.markToUpdate = true;
            }
            if (screentime_daily) {
                screentime_daily.markToUpdate = true;
            }
        }
    }

    computeTranslateLabelDaily(value, type) {
        let label= '';
        forkJoin([this.translate.get([value.key, 'parental.key.yesterday', 'parental.key.today'])])
            .subscribe( resp => {
                if (type === 'tooltip') {
                    label = value.yesterday ? resp[0]['parental.key.yesterday'] : resp[0][value.key];
                } else {
                    label = resp[0][value.key];
                }
            });
        return (value.bullet && type === 'label') ? (value.bullet + ' ' + label) : label;
    }

    computeTranslateLabelMonthly(data) {
        let label = '';
        forkJoin([this.translate.get(['parental.screentime.month.' + data.value.month, 'parental.key.yesterday', 'parental.key.today'])])
            .subscribe(resp => {
                if (data.yesterday) {
                    label = resp[0]['parental.key.yesterday']
                } else if (data.today) {
                    label = resp[0]['parental.key.today']
                } else {
                    label = resp[0]['parental.screentime.month.' + data.value.month] + ' ' + data.value.day;
                }
            });
        return label;
    }

    listInfoAccountSettings(): Observable<any> {
        if(!this.markToUpdateSetting) {
            return of(this.settingsAccount);
        }


        if (this.onInfoAccountSettings.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onInfoAccountSettings.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        } else {
            this.onInfoAccountSettings.next(this.valuesService.processServiceState.INPROGRESS);
            return this.parentalcontrolSettingsService.getAccountSettings([])
            .pipe(
                map(
                    resp => {
                        if (resp) {
                            this.settingsAccount = resp;
                            this.markToUpdateSetting= false;
                            this.onInfoAccountSettings.next(this.valuesService.processServiceState.DONE);
                        } else {
                            this.markToUpdateSetting = true;
                            this.onInfoAccountSettings.next(this.valuesService.processServiceState.DONE);
                        }
                    }
                ),
                catchError( (err)=> {
                    this.markToUpdateSetting = true;
                    this.onInfoAccountSettings.next(this.valuesService.processServiceState.DONE);
                    throw err;
                })
            );
        }
    }

    editAccountSettings(params): Observable<any> {
        return this.parentalcontrolSettingsService.editAccountSettings(params)
        .pipe(
            map(
                resp => {
                    if (resp.status === 0) {
                        for (const key in params) {
                            this.settingsAccount[key] = params[key];
                        }
                    } else {
                        return throwError(resp);
                    }
                }
            ),
            catchError(
                (err) => {
                    throw err;
                }
            )
        )
    }

    getSettingsAccount() {
        return this.settingsAccount;
    }

    updateApps(profileId) {
        const profiles = this.profilesService.getParentalProfiles();
        const profileHash = this.usefulService.crc32Convert(profileId);
        const apps = this.usefulService.getNested(profiles, {}, profileHash, "apps");

        if (this.onListApps.value !== this.valuesService.processServiceState.INPROGRESS) {
            apps["markToUpdate_list"] = true;
        }
    }

    updateWeb(profile_id) {
        const profiles = this.profilesService.getParentalProfiles();
        const profileHash = this.usefulService.crc32Convert(profile_id);
        const websites = this.usefulService.getNested(profiles, {}, profileHash, "websites");

        if (this.onListApps.value !== this.valuesService.processServiceState.INPROGRESS) {
            websites["markToUpdate"] = true;
        }
    }

    updateSetingsLocation(profile_id) {
        const profiles = this.profilesService.getParentalProfiles();
        const profileHash = this.usefulService.crc32Convert(profile_id);

        if (this.onSettingsLocation.value !== this.valuesService.processServiceState.INPROGRESS) {
            if (!profiles[profileHash]) {
                profiles[profileHash] = {};
            }

            if (!profiles[profileHash]['locationSettings']) {
                profiles[profileHash]['locationSettings'] = {};
            }

            profiles[profileHash]['locationSettings']['markToUpdate'] = true;
        }
    }

    updateSetingsContacts(profile_id) {
        const profiles = this.profilesService.getParentalProfiles();
        const profileHash = this.usefulService.crc32Convert(profile_id);

        if (this.onListContacts.value !== this.valuesService.processServiceState.INPROGRESS) {
            if (!profiles[profileHash]) {
                profiles[profileHash] = {};
            }

            if (!profiles[profileHash]['contacts']) {
                profiles[profileHash]['contacts'] = {};
            }

            if (!profiles[profileHash]['contacts']['all']) {
                profiles[profileHash]['contacts']['all'] = {};
            }

            if (!profiles[profileHash]['contacts']['latestAdded']) {
                profiles[profileHash]['contacts']['latestAdded'] = {};
            }

            profiles[profileHash]["contacts"]['latestAdded']['markToUpdate'] = true;
            profiles[profileHash]['contacts']['all']['markToUpdate'] = true;
        }
    }

    extract(totals) {
        const ids = [];

        for(let q = 0; q < this.dword1.length; ++q) {
            if(totals[0] & this.dword1[q]['shifted']) {
                ids.push(this.dword1[q]._id);
            }
        }

        for(let d = 0; d < this.dword2.length; ++d) {
            if(totals[1] & this.dword2[d]['shifted']) {
                ids.push(this.dword2[d]._id);
            }
        }

        return ids;
    };

    get_all() {
        return this.dword1.concat(this.dword2);
    }

    get_totals_categories_for_save(ids) {
        const items = [];

        for (let p = 0; p < this.dword1.length; ++p) {
            if (ids.indexOf(this.dword1[p]._id) > -1) {
                items.push(this.dword1[p]);
            }
        }

        for (let n = 0; n < this.dword2.length; ++n) {
            if (ids.indexOf(this.dword2[n]._id) > -1) {
                items.push(this.dword2[n]);
            }
        }

        // _timeline_ftime = new Date().setHours(0,0,0);
        // _timeline_etime = new Date().setHours(23,59,59);

        let n1 = 0;
        let n2 = 0;
        let nShift;
        items.forEach(function(item) {
            if (item.bitShift === 31) {
                nShift = 1 << 30;
                nShift *= 2;
            } else {
                nShift = 1 << item.bitShift >>> 0;
            }
            if (item.number === 1) {
                n1 = n1 + (nShift );
            } else {
                n2 = n2 + (nShift );
            }
        });

        return [n1, n2];
    };

    listWebSettings(profileHash): Observable<any> {
        const profiles = this.profilesService.getParentalProfiles();
        const checkFlag = this.usefulService.getNested(profiles, undefined, profileHash, "websites", "markToUpdate");

        if(checkFlag !== undefined && !checkFlag){
            return of(profiles);
        }

        if (this.onListWebSettings.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onListWebSettings.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        } else {
            this.onListWebSettings.next(this.valuesService.processServiceState.INPROGRESS);
            return this.parentalControlWebServiceAreasService.listWebSettings(profileHash)
            .pipe(
                map(
                    resp => {
                        if (!profiles[profileHash]) {
                            profiles[profileHash] = {};
                        }
                        if (!profiles[profileHash]['websites']) {
                            profiles[profileHash]['websites'] = {};
                        }
                        if (resp) {
                            profiles[profileHash]['websites']['settings'] = resp;
                            profiles[profileHash]['websites']['markToUpdate'] = false;

                            profiles[profileHash]['websites']['all'] = this.get_all();

                            profiles[profileHash]['websites']['list_selection'] = this.extract(profiles[profileHash]['websites']['settings']['categories']);

                            this.onListWebSettings.next(this.valuesService.processServiceState.DONE);
                        } else {
                            profiles[profileHash]['websites']['markToUpdate'] = true;
                            this.onListWebSettings.next(this.valuesService.processServiceState.DONE);
                            return throwError(resp);
                        }
                    }
                ),
                catchError( (err)=> {
                    if (!profiles[profileHash]['websites']) {
                        profiles[profileHash]['websites'] = {};
                    }
                    profiles[profileHash]['websites']['markToUpdate'] = true;
                    this.onListWebSettings.next(this.valuesService.processServiceState.DONE);
                    throw err;
                })
            );
        }
    }

    concatExceptions(newA, oldA) {
        for (const newException of newA) {
            let found = false;
            for (const oldException of oldA) {
                if (newException.value === oldException.value){
                    oldException.value = newException.value;
                    oldException.status = newException.status;
                    found = true;
                }
            }

            if (!found) {
                oldA.push(newException);
            }
        }
        return oldA;
    }

    changeExceptions(profileHash, params, concat?) {
        const profiles = this.profilesService.getParentalProfiles();
        const settings = this.usefulService.getNested(profiles, {}, profileHash, "websites", "settings");
        return this.parentalControlWebServiceAreasService.setWebSettings(params)
        .pipe(
            map(
                (resp) => {
                    if (resp) {
                        if (concat) {
                            const list = Array.isArray(settings.list) && settings.list.length > 0 ? settings.list : [];
                            settings.list = this.concatExceptions(params.restrictions, list);
                        } else {
                            const newList = [];
                            for (const restriction of params.restrictions) {
                                if (!restriction.remove) {
                                    newList.push(restriction);
                                }
                            }
                            settings.list = newList;
                        }
                    } else {
                        return throwError(resp);
                    }
                }
            ),
            catchError(
                (err) => {
                    throw err;
                }
            )
        );
    }

    switchMonitoring(profileHash, params) {
        const profiles = this.profilesService.getParentalProfiles();
        const settings = this.usefulService.getNested(profiles, {}, profileHash, "websites", "settings");
        return this.parentalControlWebServiceAreasService.setWebSettings(params)
        .pipe(
            map(
                (resp) => {
                    if (resp) {
                        settings["state_feature"] = params.state_feature;
                    } else {
                        return throwError(resp);
                    }
                }
            ),
            catchError(
                (err) => {
                    throw err;
                }
            )
        );
    }

    changeCategories(params, newCategories, profileHash) {
        const profiles = this.profilesService.getParentalProfiles();
        const websites = this.usefulService.getNested(profiles, {}, profileHash, "websites");
        return this.parentalControlWebServiceAreasService.setWebSettings(params)
        .pipe(
            map(
                (resp) => {
                    if (resp) {
                        websites.list_selection = newCategories;
                    } else {
                        return throwError(resp);
                    }
                }
            ),
            catchError(
                (err) => {
                    throw err;
                }
            )
        );
    }

    assignDeviceToProfile(profileHash, deviceId, sid, profileId?): Observable<any> {
        let profile: any = {};
        if (profileId) {
            // folosim profileId atunci cand se adauga un device unui copil nou creat
            // si nu suntem pe path-ul lui ca sa putem sa ii luam hash-ul din url
            const profiles = this.profilesService.getAllObjectProfiles();
            profile        = profiles[profileId];
        } else {
            const profiles    = this.profilesService.getParentalProfiles();
            profile           = this.usefulService.getNested(profiles, {}, profileHash);
        }
        const device = this.devicesService.retrieveDeviceById(deviceId);
        const params = {
            "profile_id": profile.profile_id,
            "device_id": deviceId
        }

        if (sid) {
            params["device_account_sid"] = sid;
        }

        return this.connectUserInfoService.profileSetActive(params)
        .pipe(
            map(
                resp => {
                    if (resp.status === 0) {
                        if ((sid === 0 || sid) &&  device.device_account_sid !== sid) {
                            device.device_account_sid = sid;
                        }
                        device.profile_id = profile.profile_id;
                        device["device_profile"] = profile;
                        this.devicesService.processingMethods.addAccounts(device);
                    } else {
                        return throwError(resp);
                    }
                }
            ),
            catchError(
                (err) => {
                    throw err;
                }
            )
        )

    }

    unassignDevice(deviceId, profileHash) {
        const profiles  = this.profilesService.getParentalProfiles();
        const profile = profiles[profileHash];

        if (!profile) {
            return throwError(false);
        }

        return this.connectUserInfoService.profile_set_inactive({device_id: deviceId, profile_id: profile.profile_id})
        .pipe(
            map(
                resp => {
                    if (resp) {
                        const device = this.usefulService.getNested(profile, null, "devices_monitored", "all", deviceId);
                        device.profile_id = 0;
                        if (device) {
                            if (device.device_profile) {
                                delete device.device_profile;
                            }
                            delete profile.devices_monitored.all[deviceId];
                        }
                        return resp;
                    } else {
                        return throwError(resp);
                    }
                }
            ),
            catchError((err) => {
                throw err;
            })
        );
    }

}