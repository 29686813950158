import { ModalName } from "../../../components/ui/ui-modal-routeless/modal.routeless.service";

export class ModalProperties {
    /**
     * Is modal activated
     * @type {boolean}
     */
    activated: boolean;
    /**
     * Which modal is activated
     * @type {string}
     */
    modalActivated: ModalName;
    /**
     * Is modal in the process of opening
     * @type {boolean}
     */
    modalOpening: boolean;
    activeStepInActiveModal: any;

    /**
     * Is modal in the process of animating
     * @type {boolean}
     */
    isAnimating: boolean;
}

export class ModalContainerOptions {
    /**
     * Modal dialog size
     * @type {ModalSize} 
     * @defaultValue ModalSize.MD
    */
    size?: ModalSize = ModalSize.MD;
    /**
     * Modal dissmisable by backdrop-click
     * @type {boolean} 
     * @defaultValue true
    */
    backdropDismissable?: boolean = true;
    /**
     * Modal's default close button shown
     * @type {boolean} 
     * @defaultValue true
    */
    buttonDismissable?: boolean = true;
    /**
     * Extra Class to be added on modal dialog, usualy when custom styling is needed
     * @type {string} 
    */
    exceptionClass?: string;
    /**
     * Flag to mark modal as par of a flow. If true - all other defined containerOptions except for reverseAnimation are ignored, they get
     * inherited from the previous modal. Dialog animation becomes boxed as part of the same modal.
     * @type {boolean} 
     * @defaultValue false
    */
    isFlow?: boolean = false;
    /**
     * Flag to change direction of animation (dialog's movement). usualy needed in case of flows, or back buttons
     * @type {boolean} 
     * @defaultValue false
    */
    reverseAnimation?: boolean = false;
}

export enum ModalSize {
    /** width: 440px */
    SM = 'sm',
    /** width: 600px */
    MD = 'md',
    /** width: 790px */
    LG = 'lg',
    /** width: 880px */
    XL = 'xl',
    /** width: 960px */
    XXL = 'xxl',
    /** width: auto. 
     * In this case the modal adjusts to it's content's size, width and responsivity needs to be controlled from the loaded component's css.
    */
    AUTO = 'auto'
}