import { Inject, Injectable } from '@angular/core';
import { Theme, light, dark, Color } from '../interfaces/itheme/itheme.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { ConfigService } from '../../config/config.service';
import { MessageService } from './message.service';
import { ValuesService } from '../../values/values.service';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

    private _theme;
    private _themes = {
        default: light,
        dark: dark
    }

    constructor(
        public sanitizer: DomSanitizer,
        private configService: ConfigService,
        private messageService: MessageService,
        private valuesService: ValuesService,
        @Inject(DOCUMENT) private readonly document: Document
    ) {
    }

    /**
    * This loads scss theme file in html header  
    * @param {styleName}
    * @returns {nothing} Adds css file to header of html
    */
    loadStyle(styleName: string) {
        const head = this.document.getElementsByTagName('head')[0];
        const themeLink = this.document.getElementById('client-theme') as HTMLLinkElement;

        if (themeLink) {
          themeLink.href = `${styleName}?v=${Date.now()}`;
        } else {
          const style = this.document.createElement('link');
          style.id = 'client-theme';
          style.rel = 'stylesheet';
          style.href = `${styleName}?v=${Date.now()}`;
    
          head.appendChild(style);
        }
    }

    setDarkTheme(): void {
        this.setTheme(dark);
    }

    setLightTheme(): void {
        this.setTheme(light);
    }

    getTheme() {
        return this._theme;
    }

    setTheme(theme: Theme): void {
        if (navigator.cookieEnabled) {
            sessionStorage.setItem(this.valuesService.cookieTheme, theme.name);
        }
        this._theme = theme;
        this.messageService.sendMessage(this.valuesService.events.themeChange, {})
        this.loadStyle(`${theme.name}.css`);
    }

    initTheme() {
        let theme;
        if (navigator.cookieEnabled && sessionStorage.getItem(this.valuesService.cookieTheme)) {
            theme = this._themes[sessionStorage.getItem(this.valuesService.cookieTheme)];
        } else {
            theme = this._themes[this.configService.config.defaultTheme];
        }

        this.setTheme(theme);
    }

    private hexToRgb(hex) {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? [
          parseInt(result[1], 16),
          parseInt(result[2], 16),
          parseInt(result[3], 16)
        ] : null;
    }

    regenerate(item) {
        if (typeof item === 'function') {
            return item();
        }
        if (Array.isArray(item)) {
            const newArray = [];
            for (const arrayItem of item) {
                newArray.push(this.regenerate(arrayItem));
            }
            return newArray;
        } else if (typeof item === 'object') {
            const newObject = {};
            for (const itemPropertie in item) {
                newObject[itemPropertie] = this.regenerate(item[itemPropertie]);
            }
            return newObject;
        }
        return item;
    }

    // Function to use only with [style.color] or [style.backgroundColor] (usualy custom components)
    // Works only for elements which accept css color variables (some may take only hex & rgb/rgba)
    getVariableColor(colorKey: Color, alpha = 1) {
        return `rgba(var(--${colorKey}), ${alpha})`;
    }

    // Function to use with canvas elements, usualy charts, and other components which need to force change the colors on theme change
    getColor(color: Color, alpha = 1) {
        const colorRGB = this.hexToRgb(this._theme.colors[color]);
        return `rgba(${colorRGB}, ${alpha})`;
    }

    getType(){
        return this._theme.type;
    }

}
