// External
import { Injectable } from "@angular/core";
import { Observable, of, throwError } from 'rxjs';
import { tv4 } from "tv4";
import { map, catchError } from "rxjs/operators";
import { CookieService } from "ngx-cookie-service";
import { Router } from "@angular/router";

// Internal
import { RequestsService } from "../../global/request-service/requests.service";
import { schemas } from "src/app/common/models/schemas";
import { ValuesService } from '../../../../common/values/values.service';
import { UtilsCommonService } from "src/app/common/utils/utils-common.service";
import { ConfigService } from "src/app/common/config/config.service";

@Injectable({
    providedIn: "root"
})
export class ParentalReportsMgmtService {
    constructor(
        private requestsService: RequestsService,
        private utilsCommonService: UtilsCommonService,
        private valuesService: ValuesService,
        private configService: ConfigService,
        private cookieService: CookieService,
        private router: Router
    ) { }

    getDailyActivity(info): Observable<any> {
        // if (!tv4.validate(info.profile_id, schemas.schemaUuid)) {
        //     console.error("Invalid params", tv4.error, );
        //     return of("Invalid params");
        // }

        let fieldsSwitcher = {
            dashboard : ["screentime", "apps", "web", "contacts"],
            schedule  : ["screentime"],
            screentime: ["screentime"]
        }

        let timestamp_sec = Math.floor((new Date().getTime()) / 1000);
        let noDays = info.noDays;
        let json: any = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "getDailyActivity",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                connect_destination: {
                    device_id: info.device_id,
                    app_id: this.valuesService.bundlePA
                },
                profile_id: info.profile_id,
                day: timestamp_sec,
                days_span: noDays
            }
        };
        if (info.hasOwnProperty("fields")) {
            // if (!this.utilsCommonService.checkArray(info.fields)) {
            //     console.error("Invalid params", tv4.error);
            //     return of("Invalid params");
            // }
            json.params.fields = fieldsSwitcher[info.fields];
            // json.params.fields = info.fields;
        }

        return this.requestsService.make(
            this.configService.config.nimbusServer, 
            this.valuesService.parentalControlReportsService, 
            json, 
            "POST"
        ).pipe(
            map((resp) => {
                return resp.result;
            }),
            catchError((error) => {
                if (error.redirect500) {
                    return this.router.navigate(['/500'], { skipLocationChange: true });
                } else {
                    return throwError(error);
                }
            })
        );
    }

    getWebUsage(info): Observable<any> {
        const timestamp_sec = Math.floor((new Date().getTime()) / 1000);

        const json: any = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "getWebUsage",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                connect_destination: {
                    app_id: this.valuesService.bundlePA
                },
                profile_id: info.profile_id,
                day: timestamp_sec,
                days_span: 7
            }
        };

        // ! De validat mai departe
        if (info.hasOwnProperty("fields")) {
            json.params.fields = info.fields;
        }

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.parentalcontrolWebSettingsService,
            json,
            "POST"
        ).pipe(
            map((resp) => {
                return resp.result;
            }),
            catchError((error) => {
                // if (error.redirect500) {
                //     return this.router.navigate(['/500'], { skipLocationChange: true });
                // } else {
                    return throwError(error);
                // }
            })
        );
    }

    getHourlyActivity(info): Observable<any> {

        let timestamp_sec = Math.floor((new Date().getTime()) / 1000);

        let json: any = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "getHourlyActivity",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                connect_destination: {
                    device_id: info.device_id,
                    app_id: this.valuesService.bundlePA
                },
                profile_id: info.profile_id,
                day: timestamp_sec
            }
        };

        // ! De validat mai departe
        if (info.hasOwnProperty("fields")) {
            json.params.fields = info.fields;
        }

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.parentalControlReportsService,
            json,
            "POST"
        ).pipe(
            map((resp) => {
                return resp.result;
            }),
            catchError((error) => {
                if (error.redirect500) {
                    return this.router.navigate(['/500'], { skipLocationChange: true });
                } else {
                    return throwError(error);
                }
            })
        );
    }

    getLastLocation(profile_id) {
        if (!tv4.validate(profile_id, schemas.schemaUuid)) {
            console.error("Invalid params", tv4.error);
            return of("Invalid params");
        }
        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "getDailyActivity",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                profile_id: profile_id,
                fields: ['location'],
                day: parseInt((new Date().getTime() / 1000).toString(), 10) - (60*60*24),
                days_span: 1
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.parentalControlReportsService,
            json,
            'POST'
        ).pipe(
            map((resp) => {
                return resp.result;
            }),
            catchError((error) => {
                return throwError(error);
            })
        );
    }

}