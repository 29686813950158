import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { LoaderService } from '../core/loader.service';

export interface CanComponentDeactivate {
    canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
  }

@Injectable()
export class DeactivateService implements CanDeactivate<any> {
    component: Object;
    route: ActivatedRouteSnapshot;

    constructor() {}

    canDeactivate(component: CanComponentDeactivate): Observable<boolean> | Promise<boolean> | boolean {
        if (component) {
            return component.canDeactivate ? component.canDeactivate() : true;
        } else {
            return true;
        }
    }
}